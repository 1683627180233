.cart-list {
    max-height: calc(180rem / 16);
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--color-default);
}
.cart-list::-webkit-scrollbar {
    width: calc(6rem/16)
}
.cart-list::-webkit-scrollbar-track {
    background: none;
}
.cart-list::-webkit-scrollbar-thumb {
    background: #D8D8D8;
}

.cart-list__item {
    display: grid;
    grid-template-columns: 45% 1fr auto;
    grid-gap: calc(4rem / 16);
    align-items: center;
    min-height: calc(60rem / 16);
    padding: calc(6rem / 16) calc(20rem / 16);
    cursor: pointer;
    position: relative;
    z-index: 0;
    margin: 0;
}
.cart-list__item-input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.cart-list__item-title:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-light-grey);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: -1;
}
.cart-list__item-input:checked ~ .cart-list__item-title:after {
    opacity: 1;
}
.cart-list__item-input:checked ~ .cart-list__item-title {
    font-family: var(--font-default-bold);
    font-weight: 400;
}
.cart-list__item-input:checked ~ .cart-list__item-count {
    background: #fff;
}
.cart-list__item-img {
    width: calc(50rem / 16);
    height: auto;
}
.cart-list__item-img-container {
    display: grid;
    align-items: center;
    grid-gap: calc(4rem / 16);
    grid-template-columns: 1fr 1fr 1fr;
}

.cart-list__item-count {
    background: var(--color-light-grey);
    border-radius: 50%;
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
}