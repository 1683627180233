html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h2, 
.h2 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: 600;
    font-family: var(--font-chronicle);
}

h3, h4, h5, h6,
 .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-family: var(--font-default);
 }

h1, .h1 {
    font-size: calc(65rem/16);
    line-height: calc(110/130);
    font-family: var(--font-default);
}
h2, .h2 {
    font-size: calc(24rem/16);
    line-height: calc(35/28);
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: calc(26/22);
}
h4, .h4 {
    font-size: calc(16rem/16);
    line-height: calc(20/16);
}
h5, .h5 {
    font-size: calc(15rem/16);
}
h6, .h6 {
    font-size: calc(14rem/16);
}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(130rem/16);
    }
    h2, .h2 {
        font-size: calc(28rem/16);
    }
    h3, .h3 {
        font-size: calc(22rem/16);
    }
}

.icon-1x{
    font-size: 1.33em;
}

.font-default{
    font-family: var(--font-default);
}

.text-underline{
    text-decoration: underline;
}
