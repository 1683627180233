.custom-radio{
    position: relative;
    padding-left: calc(30rem/16);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
    margin-bottom: calc(10rem/16);
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    border: calc(2rem/16) solid var(--color-default);
    background: transparent;
    width: calc(20rem/16);
    height: calc(20rem/16);
    margin-top: .1em;
    border-radius: 50%;
    z-index: 1;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(7rem/16);
    height: calc(7rem/16);
    border-radius: 50%;
    background: var(--color-default);
    visibility: hidden;
}

.custom-radio__active-box {
    position: absolute;
    top: calc(6rem/16);
    left: calc(6rem/16);
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}

.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}

.custom-radio__input:checked ~ .custom-radio__box ~ .custom-radio__active-box{
    visibility: visible;
}

.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-default);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}

.custom-radio--centered > label {margin-bottom: 0;}
.custom-radio--centered .custom-radio__box {
    top: 50%;
    transform: translateY(-70%);
}
.custom-radio--centered .custom-radio__box-active {
    top: 50%;
    transform: translateY(-50%) translateX(25%);
}

/* custom-radio--lined */

.custom-radio.custom-radio--lined{
    padding-left: 0;
}

.custom-radio--lined .custom-radio__input:checked ~ .custom-radio__text{
    font-family: var(--font-default-bold);
}

.custom-radio--lined .custom-radio__text{
    padding-bottom: calc(5rem/16);
    position: relative;
}

.custom-radio--lined .custom-radio__text:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(3rem/16);
    background-color: var(--color-default);
    transform: scale(0, 1);
    transition: all 250ms ease;
}

.custom-radio--lined .custom-radio__input:checked ~ .custom-radio__text:after,
.custom-radio--lined .custom-radio__text:hover:after{
    transform: scale(1);
}

.custom-radio__color-box{
    width: calc(30rem/16);
    height: calc(20rem/16);
    display: inline-block;
}

/* custom-radio--button */

.custom-radio.custom-radio--button{
    padding-left: 0;
}

.custom-radio.custom-radio--button .custom-radio__text{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    border: 1px solid var(--color-default);
    padding: calc(14rem/16) calc(20rem/16);
    line-height: 1;
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
}
.custom-radio--button .custom-radio__text__inner {
    position: relative;
}
.custom-radio--button .custom-radio__text__inner:before{
    content: '';
    position: absolute;
    bottom: calc(-8rem/16);
    width: 100%;
    height: calc(4rem/16);
    background-color: var(--color-default);
    transform: translateX(-50%) scale(0,1);
    left: 50%;
    transition: all 250ms ease;
}

.custom-radio--button .custom-radio__text:hover .custom-radio__text__inner:before{
    transform: translateX(-50%) scale(1);
}

.custom-radio--button .custom-radio__input:checked ~ .custom-radio__text{
    background-color: var(--color-default);
    color: #fff;
}

.custom-radio img{
    pointer-events: none;
}