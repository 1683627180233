.embed-responsive.embed-responsive-cover{
    height: 100%;
}

.embed-responsive.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive.embed-responsive-cover-md{
        height: 100%;
    }

    .embed-responsive.embed-responsive-cover-md .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.embed-responsive.embed-responsive-hero-teaser-img:before{
    padding-top: 167.759%;

    @media screen and (min-width: 768px){
        padding-top: 64.28571%;
    }
}

.embed-responsive-hero-teaser-img-portrait:before {
    padding-top: 167.759%;
}

.embed-responsive-hero-teaser-img-portrait.hide-desktop {
    @media all and (min-width: 768px) {
        display: none;
    }
}

.embed-responsive-hero-teaser-img.hide-desktop {
    @media all and (min-width: 768px) {
        display: none;
    }
}

.embed-responsive-hero-teaser-img.hide-mobile {
    @media all and (max-width: 767px) {
        display: none;
    }
}
.embed-responsive.embed-responsive-hero-teaser-overview-img:before{
    padding-top: 95%;

    @media screen and (min-width: 768px){
        padding-top: 64.28571%;
    }
}

.embed-responsive.embed-responsive-gallery-tall-big:before{
    padding-top: 204%;

    @media screen and (min-width: 768px){
        padding-top: 208.33333%;
    }
}
.embed-responsive.embed-responsive-25by11:before{
    padding-top: calc( 11 / 25 * 100%);
}

.embed-responsive.embed-responsive-gallery-tall:before{
    @media screen and (min-width: 768px){
       padding-top: 180%;
    }

    padding-top: 186%;

}
.embed-responsive.embed-responsive-5by6:before{
    padding-top: calc( 6 / 5 * 100%);
}
.embed-responsive.embed-responsive-5by3:before{
    padding-top: calc( 3 / 5 * 100%);
}

.embed-responsive.embed-responsive-2by3:before{
    padding-top: calc( 3 / 2 * 100%);
}

.embed-responsive.embed-responsive-23by16:before{
    padding-top: calc( 16 / 23 * 100%);
}

.embed-responsive.embed-responsive-9by8:before{
    padding-top: calc( 8 / 9 * 100%);
}

.embed-responsive.embed-responsive-29by42:before{
    padding-top: calc( 42 / 29 * 100%);
}

@media screen and (max-width: 767px) {
    .embed-responsive.embed-responsive-1by1-xs:before {
        padding-top: 100%;
    }
    .embed-responsive.embed-responsive-2by3-xs:before {
        padding-top: 150%;
    }
}

/* img teaser embeds */
.embed-responsive-img-teaser-big:before{
    padding-top: 97.285%;
}

.embed-responsive-img-teaser-medium:before{
    padding-top: 137.724%;
}

.embed-responsive-img-teaser-small:before{
    padding-top: 109.281%;
}
/* --- */

/* img teaser grid embeds */
.embed-responsive-img-teaser-grid-big:before{
    padding-top: 91.37%;
}
.embed-responsive-img-teaser-grid-default:before{
    padding-top: 91%;
}

.embed-responsive-teaser-small:before{
    padding-top: 116.46707%;
}
.embed-responsive-teaser-middle:before{
    padding-top: 137.72455%;
}