.product-detail__img-grid{
    padding-bottom: calc(40rem/16);
    position: relative;
    @media screen and (min-width: 768px){
        padding-bottom: calc(60rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(30rem/16);
    }
}

.product-detail__img-grid:before{
    content: '';
    position: absolute;
    left: calc(-50rem/16);
    right: 0;
    background-color: var(--color-light-grey);
    height: 50%;
    width: calc(100% - 150px);
    bottom: 15px;

    @media screen and (min-width: 768px){
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 50px);
        height: 30%;
    }
}

.product-detail__img-grid>.row>.vertical-gutter__item:nth-of-type(5):after{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        right: calc(90rem/16);
        height: calc(290rem/16);
        width: 100%;
        background-color: var(--color-light-grey);
        bottom: calc(-60rem/16);
        z-index: -1;
    }

}