.checkout-steps{

}

.checkout__stepnumbers{
    width: 50px;
    min-width: 50px;
    height: 50px;
    color: white;
    background-color: black;
    border-radius: 50%;
    @media screen and (max-width: 767px) {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
}

.checkout__stepnumbers span{
    @media screen and (max-width: 767px) {
        font-size: .85em;
    }
}

.checkout-steps__line:after{
    content: "";
    height: 5px;
    position: relative;
    width: calc(100% - 100px);
    left: 25px;
    top: 50%;
    background-color: #000;
    @media screen and (max-width: 767px) {
        left: 5px;
        width: calc(100% - 42px);
    }
}

.checkout-steps__step:last-child .checkout-steps__line:after{
    display: none;
}

.checkout--blurred,
.checkout-steps__line--blurred:after{
    background-color: var(--color-dark-grey) !important;
}

.checkout-steps__text{
    position: relative;
    text-align: center;
    min-width: 100px;
    text-transform: uppercase;
    color: var(--color-dark-grey);
}

.checkout-steps__text-mobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .checkout-steps__text-mobile {
        font-size: 0.5em;
        position: absolute;
        bottom: -2em;
        left: 50%;
        min-width: auto;
        transform: translate(-50%, 0);
    }
}

.checkout-steps__line:not(.checkout-steps__line--blurred) ~ .checkout-steps__text,
.checkout-steps__line:not(.checkout-steps__line--blurred) .checkout-steps__text{
    color: #000
}

.checkout-steps__line:not(.checkout-steps__line--blurred) ~ .checkout-steps__text:after,
.checkout-steps__line:not(.checkout-steps__line--blurred) .checkout-steps__text:after {
    position: absolute;
    content: '';
    top: 10px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 60%;
    background-color: var(--color-primary);
    transform: scaleX(1.15)
}