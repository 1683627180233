.dropdown--lang{
    font-family: var(--font-default-bold);
    display: block;
}

.dropdown--lang .dropdown-toggle{
    padding: calc(5rem/16) calc(15rem/16);
}

.dropdown-menu.dropdown-menu--app-account{
    @media screen and (max-width: 767px){
        position: fixed;
        left: 0;
        top: calc(65rem/16);
    }
}