.navbar {
    background-color: #fff;
    padding: 0;
    align-items: flex-end;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.navbar.navbar--main{
    height: calc(70rem/16);

    @media screen and (min-width: 1200px){
       height: calc(140rem/16);
        align-items: flex-end;
        padding: 0;
    }
    
    @media screen and (min-width: 768px){
        height: calc(90rem/16);
        align-items: center;
        padding: 0 calc(15rem/16);
    }
    
    @media screen and (max-width: 767px){
       align-items: center;
        padding: 0;
    }
}

.navbar--main__container {
    z-index: 21;
}

.preview-mode .navbar.navbar--main {
    top: 3rem;
}

.navbar-expand-md .navbar-collapse{
    @media screen and (min-width: 1200px){
        flex-grow: 1;
        flex-basis: 100%;
    }
}

.navbar-brand{
    padding: 0;
    margin-right: 0;

    @media screen and (min-width: 768px){
        flex-grow: 0;
        margin-bottom: calc(30rem/16);
    }

    @media screen and (max-width: 1199px){
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.navbar-brand img{
    height: calc(50rem/16);
    @media screen and (min-width: 768px){
        height: calc(65rem/16);
    }
    @media screen and (min-width: 1200px){
        height: calc(80rem/16);
    }
}

.navbar-right{
    text-align: right;

    @media screen and (min-width: 1200px){
        flex-grow: 1;
        flex-basis: 100%;
        margin-bottom: calc(39rem/16);
    }
}

.navbar-right li .icon{
    @media screen and (min-width: 768px){
       min-width: calc(25rem/16);
    }
}
.navbar-right__item-icon {
    display: inline-block;
    vertical-align: 0em;
}

.navbar-expand-md .navbar-nav{
    @media screen and (min-width: 1200px){
        margin-right: calc(15rem/16);
    }

    @media screen and (max-width: 1199px){
        flex-direction: column;
        height: 100%;
    }
}

.navbar-expand-md .navbar-nav>li>.nav-link{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    padding: calc(13rem/16) calc(34rem/16) calc(13rem/16) calc(15rem/16);
    position: relative;

    @media screen and (min-width: 1200px){
        padding: calc(15rem/16) calc(15rem/16) calc(44rem/16);
    }

    @media screen and (max-width: 1199px){
       text-align: left;
    }
    
    @media screen and (min-width: 1200px) and (max-width: 1299px){
       font-size: calc(13rem/16);
    }

}

.navbar-nav>li>.nav-link{
    @media screen and (max-width: 767px){
        transition: opacity 100ms ease;
        transition-delay: 0.05s;

    }
}

.navbar-nav.is-open>li>.nav-link{
    @media screen and (max-width: 1199px){
        opacity: 0;
        transition-delay: 0.1s;

    }
}

.navbar-nav .nav-link>.nav-link__title{
    position: relative;
    @media screen and (min-width: 1200px){
       padding-bottom: calc(10rem/16);
        white-space: nowrap;
        font-size: calc(14rem/16);
        color: var(--color-text-default);
    }
}

.navbar-nav .nav-link>.nav-link__title:before{
    @media screen and (min-width: 1200px){
        content: '';
        width: 100%;
        height: calc(4rem/16);
        background-color: var(--color-primary);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) scale(0,1);
        transition: transform 250ms ease;
    }
}

.navbar-nav .nav-link:hover>.nav-link__title:before{
    @media screen and (min-width: 1200px){
        transform: translateX(-50%) scale(1);
    }
}


.navbar-expand-md .navbar-nav>li:first-child>.nav-link{
    @media screen and (min-width: 1200px){
        padding-left: 0;
    }
}

.navbar-nav__subnav{
    @media screen and (min-width: 1200px){
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fff;
        box-shadow: 0 calc(25rem/16) calc(23rem/16) calc(-10rem/16) rgba(0,0,0,.045);
        z-index: 1;
        text-transform: uppercase;
        padding: calc(20rem/16) 0 calc(75rem/16);
        display: none;
    }
    
    @media screen and (max-width: 1199px){
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: transparent;
        color: var(--color-default);
        transform: translateX(-100%);
        transition: transform .25s ease;
        padding: calc(25rem/16) calc(40rem/16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 102;

        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: left;
    }

    @media screen and (max-width: 767px){
        transform: translateX(200%);
    }
}

.navbar-nav>li.is-open .navbar-nav__subnav{
    @media screen and (max-width: 1199px){
       transform: translateX(0);
    }
}


.navbar-nav>li.is-open .navbar-nav__subnav{
    @media screen and (min-width: 1200px){
        display: block;
    }
}

.navbar-nav__subnav__title{
    font-family: var(--font-default-bold);
    display: inline-block;

    @media screen and (min-width: 1200px){
        margin-bottom: calc(15rem/16);
    }

    @media screen and (max-width: 1199px){
        padding: calc(6rem/16) 0;
        text-transform: uppercase;
    }
}

.navbar-nav__subnav ul>li{
    margin-bottom: calc(7rem/16);
}

.navbar-nav__subnav__btn{
    margin-top: calc(20rem/16);
    
    @media screen and (max-width: 1199px){
        margin-top: calc(15rem/16);
    }
}

@media screen and (max-width: 1199px){
    .navbar-teaser{
        position: relative;
    }
}

.navbar-teaser__title{
    font-family: var(--font-default-bold);
    display: inline-block;

    @media screen and (min-width: 1200px){
        margin-top: calc(10rem/16);
    }
    
    @media screen and (max-width: 1199px){
        text-transform: uppercase;
        padding: calc(6rem/16) 0;

    }
}

.navbar-right__item{
    font-size: calc(25rem/16);
    line-height: 1;
    display: inline-block;
    padding: calc(5rem/16) calc(8rem/16);
    @media screen and (max-width: 767px){
        margin-top: calc(-5rem/16);
        padding: calc(5rem/16)  calc(6rem/16) 0 0;
    }
}

.navbar-right__cart{
    position: relative;
}

.navbar-right__cart-count{
    height: calc(18rem/16);
    width: calc(18rem/16);
    border-radius: 50%;
    font-size: calc(9rem/16);
    border: 1px solid var(--color-secondary);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default-bold);
    bottom: calc(3rem/16);
    right: calc(1rem/16);
    background-color: var(--color-secondary);
    color: #fff;
}

.navbar-right__cart-count.black{
    background-color: #fff;
    color: var(--color-default);
}

.navbar-cart-notification{
    position: absolute;
    width: calc(300rem/16);
    right: 0;
    background-color: #fff;
    padding: calc(15rem/16);
    top: calc(100% + 10px);
    border: 1px solid var(--color-default);
}

.navbar-search{
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 21;
    background-color: #fff;
    color: var(--color-default);
    padding: calc(20rem/16) calc(15rem/16);
    transform: scaleY(0);
    transition: transform 300ms ease, opacity 300ms ease;
    transform-origin: top;
    opacity: 0;

    @media screen and (min-width: 768px){
        z-index: 20;
        padding: calc(30rem/16);
    }
}

.navbar-search.is-open{
    transform: scaleY(1);
    opacity: 1;
}

.navbar-search__form{
    max-width: calc(780rem/16);
    width: 100%;
    margin: 0 auto;
}


.navbar-search__group{
    position: relative;
}

.navbar-search__group__btn{
    background: var(--color-default);
    color: #fff;
    border: 1px solid var(--color-default);
    border-left: 1px solid #ffffff;
    padding: calc(10rem/16) calc(25rem/16);
    font-size: calc(20rem/16);
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.navbar-search__group__btn:hover{
    color: #fff;
}

.row--navbar-teaser{
    justify-content: flex-start;
    @media screen and (max-width: 1199px){
        margin-left: 0;
        margin-right: 0;
    }
}

.row--navbar-teaser>[class*=" col"],
.row--navbar-teaser>[class^=col] {
    @media screen and (max-width: 1199px){
        padding-left: 0;
        padding-right: 0;
    }
}


.navbar-collapse {
    @media screen and (max-width: 1199px){
        position: fixed;
        left: 0;
        bottom: 0;
        right: 70%;
        top: calc(70rem/16);
        background-color: #fff;
        color: var(--color-default);
        transform: translateX(-103%);
        transition: transform .25s ease;
        padding: calc(10rem/16) 0  0;
        z-index: 20;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (max-width: 767px){
        right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
        box-shadow: 3px 6px 5px 0 rgba(0, 0, 0, 0.18);
        padding-bottom: calc(20rem/16);
    }
}

/*
.navbar-collapse:after{
    @media screen and (max-width: 1199px){
        content: '';
        position: absolute;
        width: 45%;
        height: calc(200rem/16);
        background-color: var(--color-primary);
        z-index: 100;
        bottom: 0;
        right: 0;
    }
}
*/

@media screen and (max-width: 1199px){

    .navbar>.container{
        padding: 0;
    }

    .navbar-expand-md .navbar-toggler{
        display: block;
    }
    
    .navbar-toggler{
        padding: 0;
        width: calc(40rem/16);
        height: calc(40rem/16);
        background-color: #fff;
        border-radius: 0;
        margin-right: calc(10rem/16);
    }

    .navbar-toggler:focus{
        outline: 0;
    }

    .navbar-toggler__text{
        font-size: calc(9rem/16);
        text-transform: uppercase;
        letter-spacing: calc(1rem/16);
        margin-top: calc(8rem/16);
        margin-left: calc(2rem/16);
        display: block;
        font-family: var(--font-default-bold);
    }
    
    .navbar-toggler__bar {
        display: block;
        width: 24px;
        height: 2px;
        background-color: var(--color-default);
        transition: transform .25s ease-in-out,opacity .25s ease-in-out;
        border-radius: calc(2rem/16);
        margin: 0 auto;
    }

    .navbar-toggler__bar+.navbar-toggler__bar {
        margin-top: 5px;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:first-child {
        transform: rotate(-45deg) translateX(-3px) translateY(6px);
        width: 27px;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-4px) translateY(-7px);
        width: 27px;
    }



    .navbar-collapse.is-open{
        transform: translateX(0);
    }

    .navbar-nav{
        width: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 calc(40rem/16);
    }

    .navbar-nav>li{
        position: relative;
    }

    .navbar-nav__subnav__toggle{
        position: relative;
        padding: calc(7rem/16) calc(10rem/16);
        padding-right: 0;
        margin-right: calc(-25rem/16);
        top: calc(2rem/16);
        margin-left: calc(3rem/16);
    }

    .navbar-nav__subnav .navbar-nav__subnav__toggle:before{
        display: inline-block;
        transform: rotate(90deg);
    }

    .navbar-nav__subnav li.is-open  .navbar-nav__subnav__toggle:before{
        transform: rotate(-90deg);
    }

    .navbar__bottom{
        position: absolute;
        bottom: calc(0rem/16);
        padding: calc(25rem/16) calc(55rem/16);
        left: 0;
        right: 0;
        text-align: left;
        z-index: 101;
        transition: opacity 100ms ease;
        transition-delay: 0.05s;
    }

    .navbar-nav.is-open + .navbar__bottom{
        opacity: 0;
        transition-delay: 0.1s;
    }

    .navbar-nav__subnav__back-toggle{
        text-transform: uppercase;
        font-family: var(--font-default-bold);
        padding-bottom: calc(15rem/16);
        border-bottom: 1px solid var(--color-default);
        width: 100%;
        text-align: left;
        margin-bottom: calc(35rem/16);
        position: relative;
    }

    .navbar-nav__subnav__back-toggle>.icon{
        vertical-align: calc(-2rem/16);
        margin-right: calc(6rem/16);
    }

    .navbar-nav__subnav__sub{
        display: none;
        margin-top: calc(10rem/16);
        margin-bottom: calc(20rem/16);
    }

    .navbar-nav__subnav li.is-open .navbar-nav__subnav__sub{
        display: block;
    }

    .navbar-nav__subnav .navbar-nav__subnav__sub li{
        margin-bottom: calc(10rem/16);
    }
}