.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.form-group.has-error{
    position: relative;
}
.form-errors.invalid-feedback {
    display: block;
}
.file-input .form-errors {
    position: relative;
    left: 0;
}