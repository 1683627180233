html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/

    @media screen and (min-width: 768px) and (max-width: 991px) {
        /* Table scaling */
        font-size: calc(16 / (991 / 100) * 1vw);
    }

    @media screen and (min-width: 2000px) and (max-width: 3999px) {
        /*Scale the whole page up on bigger screens*/
        font-size: calc(16vw / 2000 * 100);
    }

    @media screen and (min-width: 4000px) {
        font-size: 32px;
    }

}

body {
    position: relative;
    @media screen and (max-width: 767px) {
        min-width: calc(320rem / 16);
        max-width: 100%;
        overflow-x: hidden;
    }
    line-height: calc(22/14);
}


body.open-overlay,
body.lg-on{
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.container-outer{
    overflow: hidden;
}

.main-content{
    padding-top: calc(70rem/16);
    
    @media screen and (min-width: 768px){
       padding-top: calc(140rem/16);
    }
}

.preview-mode .main-content {
    padding-top: 7.375rem;

    @media screen and (min-width: 768px){
        padding-top: 11.75rem;
    }
}

.container.container {
    max-width: calc(1494rem/16); /* 1430px + 64px padding */
    width: 100%;
    padding-left: calc(32rem/16);
    padding-right: calc(32rem/16);

    @media screen and (max-width: 767px) {
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}

.container.container-lg {
    max-width: calc(1573rem / 16);
}

.container-narrow{
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;

    max-width: calc(1009rem/16); /* 945px + 64px padding */
    width: 100%;
    
    @media screen and (min-width: 768px){
        padding-left: calc(32rem/16);
        padding-right: calc(32rem/16);
    }
}

.container__break-on-xs {
    @media screen and (max-width: 767px) {
        margin-left: calc(-20rem/16);
        margin-right: calc(-20rem/16);
    }
}