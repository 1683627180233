.quote__text {
    position: relative;
    text-align: center;
    font-size: calc(23rem/16);
    max-width: calc(520rem/16);
    padding-bottom: calc(50rem/16);
    line-height: 1.57143;

    @media screen and (max-width: 767px) {
        padding-bottom: calc(40rem/16);
        font-size: calc(20rem/16);
    }
}
.quote__text:before {
    content: var(--icon-quote);
    font-family: iconfont;
    position: absolute;
    top: 25%;
    left: calc(-15rem/16);
    color: var(--color-primary);
    font-size: calc(100rem/16);
    z-index: -1;

    @media screen and (max-width: 767px) {
        top: 30%;
        left: calc(-5rem/16);
        font-size: calc(70rem/16);
    }
}

.quote__has-image .quote__text {
    @media screen and (min-width: 768px) and (max-width: 1200px){
       margin-left: calc(40rem/16);
    }
}

.quote__img-wrapper {
    line-height: 1.5;

    @media screen and (min-width: 768px){
        margin-left: calc(40rem/16);
        margin-right: calc(50rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-left: calc(125rem/16);
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
}
.quote__img {
    position: relative;
}
.quote__img:after {
    content: '';
    position: absolute;
    width: 67%;
    height: 70%;
    top: 45%;
    right: calc(-60rem/16);
    background-color: var(--color-primary);
    z-index: -1;

    @media screen and (max-width: 767px) {
        right: calc(-30rem/16);
    }
}
.quote-image-dropper {
    width: 275px;
    height: 275px;
}

.quote__src {
    font-size: calc(22rem/16);
    line-height: calc(26 / 22);
    text-transform: uppercase;
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: calc(19rem/16);
    }
}

.quote-slider {
    width: calc(1054rem / 16);
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-bottom: calc(70rem / 16);
}
.quote-slider .slick-track {
    display: flex;
    align-items: center;
}
.quote-slider .slider__arrow-styled.slick-prev{
    right: calc(68rem/16);
}
.quote-slider .slider__arrow-styled.slick-next{
    right: calc(10rem / 16);
}

.quote-slider .quote-slider-centered-quote {
    line-height: initial;
}