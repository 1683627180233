@font-face {
    font-family: 'Gotham-Bold';
    src: url('/static/fonts/Gotham-Bold.woff2') format('woff2'),
    url('/static/fonts/Gotham-Bold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tungsten-Semibold';
    src: url('/static/fonts/Tungsten-Semibold.woff2') format('woff2'),
    url('/static/fonts/Tungsten-Semibold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}


@font-face {
    font-family: 'Gotham-Book';
    src: url('/static/fonts/Gotham-Book.woff2') format('woff2'),
    url('/static/fonts/Gotham-Book.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Chronicle-Text';
    src: url('/static/fonts/ChronicleTextG1-Regular-Pro_Web.woff2') format('woff2'),
    url('/static/fonts/ChronicleTextG1-Regular-Pro_Web.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chronicle-Text';
    src: url('/static/fonts/ChronicleTextG1-Italic-Pro_Web.woff2') format('woff2'),
    url('/static/fonts/ChronicleTextG1-Italic-Pro_Web.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Chronicle-Text';
    src: url('/static/fonts/ChronicleTextG1-Bold-Pro_Web.woff2') format('woff2'),
    url('/static/fonts/ChronicleTextG1-Bold-Pro_Web.woff') format('woff');
    font-weight: bolder;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chronicle-Text';
    src: url('/static/fonts/ChronicleTextG1-Semibd-Pro_Web.woff2') format('woff2'),
    url('/static/fonts/ChronicleTextG1-Semibd-Pro_Web.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chronicle-Text';
    src: url('/static/fonts/ChronicleTextG1-SemiIta-Pro_Web.woff2') format('woff2'),
    url('/static/fonts/ChronicleTextG1-SemiIta-Pro_Web.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}