.banner-big {
    position: relative;
    padding-top: calc(60rem/16);
    color: #fff;

    @media screen and (max-width: 767px) {
        padding-top: calc(35rem/16);
    }
}
.banner-big:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(-60rem/16);
    width: 50%;
    height: 47%;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (max-width: 767px) {
        right: 0;
    }
}

.banner-big__bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.banner-big__bg-image.hide-desktop {
    @media all and (min-width: 768px) {
        display: none;
    }
}

.banner-big__bg-image.hide-mobile {
    @media all and (max-width: 767px) {
        display: none;
    }
}

.banner-big__wrapper {
    position: relative;
    min-height: calc(625rem/16);

    @media screen and (max-width: 767px) {
        min-height: calc(450rem/16);
    }
}
.banner-big__wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    z-index: 0;
}

.banner-big__content {
    max-width: calc(460rem/16);
    padding-left: calc(120rem/16);
    z-index: 0;

    @media screen and (max-width: 767px) {
        max-width: 100%;
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.banner-big__title {
    margin-bottom: calc(15rem/16);
}
.banner-big__button {
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(25rem/16) calc(70rem/16)
    }
}

.teaser-special-white {
    color: #fff;
}

.teaser-special-black {
    color: #000;
}

.teaser-special-btn-white {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
}
.teaser-special-btn-white:hover {
    background-color: #fff;
    color: #000;
}

.teaser-special-btn-black {
    color: #000;
    background-color: transparent;
    border: 1px solid #000;
}
.teaser-special-btn-black:hover {
    background-color: #000;
    color: #fff;
}