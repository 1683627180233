.store-search__card .embed-responsive-item{
    @media screen and (max-width: 767px){
        position: relative;
    }
}
.store-search__card:before{
    @media screen and (max-width: 767px){
        display: none;
    }
}

.store-search__slider .slick-arrow{
    bottom: -1rem;
}

.store-search__card .card-body{
    padding: 1.33rem;
}

.store-search__card .btn:hover {
    border-color: var(--color-primary);
}
.store-search__card .btn-secondary, .store-search__card .btn-secondary:hover {
    background: black;
    border-color: black;
}