.teaser-product-selected__img{
    background-color: var(--color-light-grey);
}

.teaser-product-selected__body{
    padding: calc(25rem/16);
}

.teaser-product-selected__btn-list>li:not(:first-child){
    @media screen and (min-width: 768px){
        margin-left: calc(25rem/16);
    }
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}