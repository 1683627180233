/*Margin Helper*/
.mt-32 {
    margin-top: calc(32rem / 16);
}

/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge {
    padding: .4em;
}

.badge-outline {
    border: 1px solid var(--color-text-default);
    color: var(--color-text-default);
}

/*Text Color Helper*/
.text-white {
    color: #ffffff;
}

.text-color-default {
    color: var(--color-text-default);
}


/*Background Color Helper*/
.bg-light-grey {
    background-color: var(--color-light-grey);
}

.font-bold {
    font-family: var(--font-default-bold);
}

.js-go-to-link {
    cursor: pointer;
}

.w-auto {
    width: auto;
}

.multi-column-two {
    transform: translateZ(0);
    column-count: 2;
}

.btn-no-style {
    background: transparent;
    border:  none;
}

.btn-no-style:active {
    outline: none;
}

.link-with-arrow {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(12rem / 16);
}

.link-with-arrow > .icon {
    position: relative;
    display: inline-block;
    margin-right: calc(15rem / 16);
    vertical-align: calc(-2rem / 16);
    padding: calc(7rem / 16);
}

.link-with-arrow > .icon:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(25rem / 16);
    background-color: var(--color-primary);
    z-index: -1;
    transition: all 250ms ease;
    transform-origin: left;
}

.link-with-arrow:hover > .icon:after {
    transform: scaleX(1.5);
}

.tooltip {
    z-index: 500;
}

.p-6 {
    padding: 5rem;
}

.p-7 {
    padding: 7rem;
}

a.language-link{
    font-size: calc(12rem / 14);
}
a.language-link.active{
    font-family: var(--font-default-bold);
}

.border--black{
    border-color: #000 !important;
}

.pointer-cursor{
cursor: pointer;
}

.line-height-1 {
    line-height: 1;
}

.slider--same-height .slick-track,
.slider--same-height .slick-slide,
.slider--same-height .slick-slide > div {
    display: flex;
    align-content: stretch;
    flex-direction: row;
    width: 100%;
}
.slider--same-height .slick-slide > div > div {
    display: flex !important; /*override inline style*/
    flex-direction: column;
}
.slider--same-height .slick-slide {
    height: auto;
}

.product-grid__quickfilter__item__icon {
    font-size: 1.5rem;
}

.text-black {
    color: var(--color-text-default) !important;
}

.font-light {
    font-family: var(--font-default) !important;
}

.text-no-transform {
    text-transform: none !important;
}

.alert.alert-success {
    background-color: var(--color-light-grey);
    color: var(--color-dark);
    border: none;
}

.lg-sub-html {
    visibility: hidden;
}

.scrollable-x {
    overflow-x: auto;
}

.crossed-out-price {
    text-decoration: line-through;
    color: var(--gray);
}

.table tbody tr.tr-sm td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.table tbody tr td, .table thead tr th {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.notification-container.mbt .alert:first-of-type {
    margin-top: 1rem;
}

.notification-container .alert:last-of-type {
    margin-bottom: 0;
}

.cart-notifications .alert {
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    margin-bottom: 0;
}

.cart-notifications .alert > div {
    line-height: 1;
}

.cart-notifications .alert .close {
    padding: 0.25rem;
}

.mw-100 {
    max-width: 100%;
}

.mh-50 {
    min-height: 50vh;
}