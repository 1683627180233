.product-teaser-accessories{
    background-color: var(--color-light-grey);
    padding: calc(12rem/16);
    max-width: none;
    @media screen and (min-width: 768px) {
        padding: calc(20rem/16);
    }
}

.product-teaser-accessories__badge{
    text-transform: uppercase;
    background-color: #fff;
    padding: calc(8rem/16) calc(15rem/16) calc(8rem/16) calc(20rem/16);
    display: inline-block;
    position: relative;
    left: calc(-20rem/16);
    margin-bottom: calc(15rem/16);
}

.product-teaser-accessories__title{
    font-size: 1.15rem;
}

.product-teaser-accessories__subtitle{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}

.product-teaser-accessories__img{
    position: relative;
}
.product-teaser-accessories__value-list>li{
    position: relative;
}

.product-teaser-accessories__value-list>li:not(:first-child):before{
    content: '|';
    position: absolute;
    left: calc(-8rem/16);
    top: 0;
}

.product-teaser-accessories-slider__item .product-teaser-accessories{
    margin: 0 calc(10rem/16);

    @media screen and (min-width: 768px){
        margin: 0 calc(15rem/16);
    }
}