.tt-menu{
    background-color: #fff;
    border: 1px solid var(--color-default);
    border-top: none;

    @media screen and (max-width: 767px){
       max-height: calc(200rem/16);
        overflow-y: auto;

    }
}

/*
@media screen and (max-width: 767px) {
    .tt-menu:not(.tt-empty) {
        display:block !important;
    }
}
*/

.twitter-typeahead .form-control:focus{
    border-color: var(--color-default);
}

.tt-selectable.tt-cursor{
    color: black;
    font-family: var(--font-default-bold);
}

.tt-dataset+.tt-dataset{
    margin-top: calc(15rem/16);
}

.typeahead-heading {
    padding: calc(5rem/16) calc(15rem/16);
}

.typeahead-suggestion+.typeahead-suggestion{
    margin-top: calc(5rem/16);
}

.typeahead-suggestion__text{
    font-size: 12px;
}

@media screen and (max-width: 767px){
    .typeahead-heading {
        padding: calc(5rem/16) 0 calc(5rem/16) calc(5rem/16);
    }
    
    .typeahead-suggestion {
        padding: calc(2rem/16) 0 calc(2rem/16) calc(5rem/16);
    }
}

