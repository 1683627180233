.gallery-teaser{
    position: relative;
}

.gallery-teaser:not(:first-child){
    margin-top: calc(10rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(30rem/16);
    }
}

.gallery-teaser__content{

    padding: calc(6rem/16);
    z-index: 2;

    @media screen and (min-width: 1025px){
        padding: calc(23rem/16);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        bottom: 0;
        color: #fff;
        opacity: 0;
        transition: all 350ms ease;
        width: 100%;
    }
}

.text-white-all > *{
    color: #fff!important;
}

.gallery-teaser:hover .gallery-teaser__content{
    opacity: 1;
}

.gallery-teaser__title{
    margin-bottom: 0;

    @media screen and (min-width: 1025px){
        padding-left: calc(20rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(11rem/16);
    }
}

.gallery-teaser__text{
    max-height: calc(90rem/16);
    overflow: hidden;
    line-height: calc(22/14);

    @media screen and (max-width: 1024px){
       display: none;
    }
}

.gallery-teaser:before{
    @media screen and (min-width: 1025px){
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(59,61,70,0) 0%, rgba(58,58,67,0.8) 100%);
        z-index: 1;
        opacity: 0;
        transition: all 350ms ease;
    }

}

.gallery-teaser:hover:before{
    @media screen and (min-width: 1025px){
        opacity: 1;
    }
}