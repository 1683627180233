.teaser-title{
    font-size: calc(16rem/16);
    hyphens: auto;

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.product-teaser-main--accessory .teaser-title{
    font-size: calc(16rem/16);
}