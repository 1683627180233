.title-block--main{
    margin: calc(30rem/16) auto calc(30rem/16);

    @media screen and (min-width: 768px){
        margin: calc(30rem/16) auto calc(60rem/16);
    }

    max-width: calc(700rem/16);
}

.title-block__sub-title{
    font-size: calc(14rem/16);
    margin-top: calc(15rem/16);
}

.title-block{
    text-align: center;
    @media screen and (min-width: 768px){
       max-width: 50%;
        margin: 0 auto;
    }
}

.title-block.title-block--left{
    text-align: left;
    margin: 0;
}