.product-detail__title{
    font-size: calc(34rem/16);
    font-family: var(--font-default);
    line-height: calc(41/34);
    margin-bottom: 0;
}

.product-detail__article-nr{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
}

.product-detail__btn-list .btn-icon{
    font-size: calc(27rem/16);
    padding: calc(5rem/16);
}

.product-detail-filter__label{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.product-detail__price{
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
}

.product-detail__achnor-links a{
    text-decoration: underline;
}

.product-detail .btn-cart{
    @media screen and (min-width: 768px) and (max-width: 991px){
       font-size: calc(12rem/16);
    }
}

.product-detail-slider .slick-slide{
    margin-right: calc(20rem/16);
}


.product-detail-info-slide__btn-list{
    @media screen and (max-width: 767px){
       margin-top: calc(-10rem/16);
    }
}

.product-detail-info-slide__btn-list>li{
    @media screen and (min-width: 768px){
       display: inline-block;
    }

    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}

.product-detail-info-slide__btn-list>li:not(:last-child){
    @media screen and (min-width: 768px){
        margin-right: calc(25rem/16);
    }
}

.product-detail-info-slide__btn-list>li .btn{
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.product-detail__hint{
    display: flex;
    align-items: center;
    font-size: calc(14rem/16);
    line-height: calc(21/15);
    padding: calc(12rem/16) calc(3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        padding: calc(18rem/16) calc(3rem/16);
    }
}
.product-detail__hint p {
    margin-bottom: calc(5rem / 16);
}
.product-detail__hint-symbol{
    width: calc(36rem/16);
    height: calc(36rem/16);
    border: 1px solid var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(22rem/16);
    margin-right: calc(18rem/16);
    position: relative;
}

.product-detail__hint-symbol:before{
    content: '';
    position: absolute;
    top: calc(-5rem/16);
    bottom: calc(-5rem/16);
    left: calc(4rem/16);
    right: calc(4rem/16);
    /* background-color: var(--color-light-grey); */
    background-color: var(--color-light-grey);
    z-index: -1;

}

.product-detail-info-slide__img img {
    max-width: 220px;
}

.hidden_for_others {
    /* position: absolute; */
    opacity: 0;
    pointer-events: none;
    /* top: -10000px;
    left: -10000px; */
    transition: opacity 0.2s ease-in-out;
    /* z-index: 99; */
}

.js_originalImage.hide_opacity picture {
    opacity: 0;
    pointer-events: none;
    
}

.js_originalImage picture {
    transition: opacity 0.2s ease-in-out;
}

.hidden_for_others .lightbox-img {
    z-index: 99;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}


.show-color-image .lightbox-img {
    z-index: 99;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}

.product-detail-main .list-unstyled {
    min-height: 45px !important;
}
/* 
.product-detail-main .list-unstyled .list-inline-item .custom-radio {
    padding-right: 0.5rem;
} */