.product-teaser {
    position: relative;
}

.product-teaser__body{
    text-align: center;
    padding: calc(15rem/16) calc(15rem/16) calc(30rem/16);
}

.product-teaser__badges {
    position: absolute;
    top: 0;
    left: 0;
}

.product-teaser__badges .badge {
    font-size: 1rem;
    font-family: var(--font-default-bold);
}

.product-teaser__img{
    position: relative;

    @media screen and (max-width: 767px){
       padding: 0 calc(10rem/16);
    }
}

.product-teaser__watchlist .btn{
    position: absolute;
    right: calc(10rem/16);
    top: calc(10rem/16);
    z-index: 1;
    line-height: 1;
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
    }

    @media screen and (max-width: 767px){
       padding: calc(7rem/16);
    }
}

.product-teaser-main{
    position: relative;
    transition: all 250ms ease;
}

.product-teaser-main:hover {
    background-color: var(--color-light-grey);
}

.product-teaser-main__nav{
    padding-top: calc(5rem/16);
    padding-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
        position: absolute;
        bottom: calc(1rem/16);
        left: 0;
        right: 0;
        transform: translateY(100%);
        background: var(--color-light-grey);
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
        z-index: -10;
        transition: opacity 250ms ease;
        opacity: 0;
    }
}

.product-teaser-main__nav .slick-arrow.slick-next {
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    right: -1.5rem;
    outline: 0;
}

.product-teaser-main__nav .slick-arrow.slick-prev {
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    left: -1.5rem;
    outline: 0;
}

.product-teaser-main:hover .product-teaser-main__nav{
    z-index: 5;
    opacity: 1;
}

.product-teaser-main__thumb{
    cursor: pointer;
/*    width: auto!important; !* overwrite slick inline styling *!*/
    padding-bottom: calc(5rem/16);
    position: relative;
    text-align: center;
}

.product-teaser-main__thumb img{
    display: inline-block;
}

.product-teaser-main__thumb:after{
    content: '';
    position: absolute;
    height: calc(3rem/16);
    width: calc(25rem/16);
    background-color: var(--color-default);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.slick-current .product-teaser-main__thumb:after,
.product-teaser-main__thumb:hover:after{
    opacity: 1;
}

.product-teaser-main__nav .slick-slide{
/*    width: calc(46rem/16)!important;*/
    margin: 0 calc(3rem/16);

}

.product-teaser-main__nav .slick-track{
    margin: 0 auto;
}


.product-teaser-main__main .slider__arrow{
    top: calc(150rem/16);
    font-size: calc(13rem/16);
    padding: calc(6rem/16) calc(10rem/16);

    @media screen and (min-width: 768px){
        top: calc(215rem/16);

    }

    @media screen and (min-width: 992px){
        top: calc(230rem/16);

    }

    @media screen and (max-width: 767px){
       display: none;
    }
}