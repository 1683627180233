.media-area {
    position: relative;
    padding-bottom: calc(60rem/16);

    @media screen and (max-width: 767px) {
        padding-bottom: calc(20rem/16);
    }
}
.media-area:not(.media-area--highlight-img):after {
    content: '';
    position: absolute;
    right: calc(-60rem/16);
    bottom: 0;
    width: 52%;
    height: 73%;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (max-width: 767px) {
        height: 55%;
    }
}
.media-area--highlight-img .media-area__embed:after {
    content: '';
    position: absolute;
    right: calc(-60rem/16);
    bottom: calc(-60rem / 16);
    width: 52%;
    height: 100%;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (max-width: 767px) {
        height: 55%;
    }
}

.media-area video {
    cursor: pointer;
}


/* video */
.asset-video {
    position: relative;
}
.asset-video__btn {
    color: #fff;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center;
    transition: opacity 200ms ease-in-out;
    font-size: calc(85rem/16);
    border: 0;
    line-height: 1;

    @media screen and (max-width: 767px) {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: calc(65rem/16);
    }
}
.is-playing .asset-video__btn {
    opacity:0;
    pointer-events: none;
}

.asset-video__poster {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
}

.is-playing .asset-video__poster {
    opacity: 0;
}

/* hover states */
@media screen and (min-width: 768px) {
    .asset-video__btn:focus,
    .asset-video:hover .asset-video__btn {
        background-color: transparent;
        transform: translate(-50%, -50%) scale(1.25);
        transition: transform 0.3s ease, opacity 200ms ease-in-out;
    }
}