.quick-order {
    background: var(--color-light-grey);
    padding: calc(24rem / 16) calc(15rem / 16);
}
.quick-order__title {
    margin-bottom: calc(10rem / 16);
}
/*.quick-order__expand {
    position: relative;
    min-height: calc(43rem / 16);
}
.quick-order__expand-less {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 200ms ease-in-out;
}*/
.quick-order__expand {
}
.quick-order .quick-order__input {
    width: 82%;
    height: calc(43rem / 16);
    flex-shrink: 0;
    transition: all 200ms ease-in-out;
}
.is-expand .quick-order__input {
    width: 100%;
    height: calc(94rem / 16);
}
.quick-order__toggle {
    transition: opacity 200ms ease-in-out;
}
.is-expand .quick-order__toggle {
    opacity: 0;
}
.quick-order__submit {
    display: none;
}
.is-expand .quick-order__submit {
    display: block;
}
.quick-order__hr {
    display: flex;
    align-items: center;
    margin: calc(15rem / 16) 0;
    font-family: var(--font-default-bold);
    font-weight: 400;
    letter-spacing: calc(0.4rem / 16);
}
.quick-order__hr:before,
.quick-order__hr:after {
    content: '';
    height: 1px;
    background: #dee2e6;
    flex-grow: 1;
    margin: 0 calc(8rem / 16);
}
.quick-order__hr:before {
    margin-left: 0;
}
.quick-order__hr:after {
    margin-right: 0;
}
.csv-modal__product {
    display: grid;
    grid-gap: calc(5rem / 16);
    grid-template-columns: 14% 65% auto;

    @media screen and (min-width: 768px) {
        align-items: center;
        grid-template-columns: 7% 15% 26% 10% auto;
    }
}