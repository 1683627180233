.variant-select + .variant-dropdown {
    margin-top: calc(20rem / 16);
}

.variant-dropdown__btn {
    background: none;
    border: 0;
    color: var(--color-dark-grey);
    padding: 0 0 calc(4rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-dark-grey);
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    font-size: calc(16rem / 16);
}
.variant-dropdown__btn:focus {
    outline: none;
    border-color: var(--color-primary);
}
.variant-dropdown__btn:after {
    content: var(--icon-close);
    font-family: iconfont;
    border: 0;
    margin: 0;
    font-size: calc(24rem / 16);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}
.variant-select--selected + .variant-dropdown .variant-dropdown__btn:after {
    content: var(--icon-arrow-down);
    transform: translateY(-50%) rotate(0);
    font-size: calc(18rem / 16);
}

.variant-select--selected + .variant-dropdown .variant-dropdown__text-change {
    display: block;
}
.variant-select--selected + .variant-dropdown .variant-dropdown__text-select,
.variant-dropdown__text-change {
    display: none;
}

.variant-dropdown__menu {
    max-height: calc(280rem / 16);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: calc(-2rem / 16);
    min-width: 100%;
    padding: 0;
}
@media screen and (min-width: 768px) {
    .variant-dropdown__menu {
        max-height: calc(360rem / 16);
    }
}
.variant-dropdown__menu::-webkit-scrollbar {
    width: calc(6rem/16)
}
.variant-dropdown__menu::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}
.variant-dropdown__menu::-webkit-scrollbar-thumb {
    background: #d3d3d3;
}

.variant-select {
    background: none;
    border: 0;
    display: grid;
    grid-template-columns: calc(50rem / 16) calc(70rem / 16) 1fr;
    grid-gap: calc(10rem / 16);
    align-items: center;
    width: 100%;
    font-size: calc(13rem / 16);
    line-height: calc(18/13);
    padding: calc(12rem / 16) calc(30rem / 16) calc(12rem / 16) calc(12rem / 16);
    position: relative;
    margin-bottom: 0;
}
.variant-select--selected,
.variant-select:hover {
    background: var(--color-light-grey);
}
.variant-select + .variant-select {
    border-top: calc(1rem / 16) solid var(--color-light-grey);
}
.variant-select.is-selected {
    pointer-events: none;
    opacity: .4;
}
.variant-select-input[disabled],
.variant-select-input {
    padding: 5px;
    height: 40px;
    border: 0;
    background: transparent;
}
.variant-select-input:focus {
    background: transparent;
}
.variant-select-input::-webkit-inner-spin-button,
.variant-select-input::-webkit-outer-spin-button {
    opacity: 1;
}
.variant-select-title {
    font-size: calc(14rem / 16);
    margin-bottom: calc(4rem / 16);
}

.variant-select-color {
    color: var(--color-dark-grey);
}

.variant-select__remove {
    position: absolute;
    right: 0;
    background: none;
    border: 0;
    font-size: calc(20rem / 16);
    top: calc(8rem / 16);
}