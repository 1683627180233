.checkout__accordion{
    border: 1px solid #000;
    border-top: none;
}

.checkout__accordion-header{
    border-top: 1px solid #000
}

.checkout__sidebar{
    border: 1px solid #000;
    font-size: 85%;
}

.checkout__btn{
    position: relative;
}
.checkout__btn:after{
    content: '';
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 90%;
    transform: scaleX(1.15);
    background-color: var(--color-primary);
    z-index: -1;
}

.checkout__btn.btn-disabled {
    pointer-events: none;
    background: #6f757d;
    border-color: #6f757d;

}

.checkout__article-list{
    width: 100%;
    max-height: 315px;
    overflow-y: auto;
    border-bottom: 1px solid #000;
}
.checkout__sidebar .custom-radio__text__inner a {
    text-decoration: underline;
}
.checkout__article-list-head {
    background: var(--color-light-grey);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: 400;
    padding: calc(16rem / 16);
    font-size: calc(14rem / 16);
}

.checkout__article-listitem{
    min-height: 70px;
}

.checkout__bulletpoint{
    display: flex;
    width: 38px;
    height: 38px;
    flex-shrink: 0;
    background-color: #000;
    color: #fff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-family: var(--font-default-bold);
    font-size: 1.2em;
}