.productLightbox__lightbox .lg-sub-html{
    height: 120px;
    visibility: visible;
}

.productLightbox__product{
    background-color: var(--color-light-grey);
    padding: calc(20rem/16);

}

.productLightbox__slider{
    height: 100px;
    width: 100%;
}

.productLightbox__slide--has-html .lg-image{
    padding-bottom: 115px;
}