.select {
    position: relative;
}
.select > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: calc(40rem / 16);
    cursor: pointer;
}
.select select::-ms-expand {
    display: none;
}

.select:before {
    font-family: "iconfont";
    content: var(--icon-down);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(16rem / 16);
    line-height: 1;
    font-size: calc(18rem / 16);
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-default);
}

.form-group--inline {
    display: flex;
    align-items: flex-end;
}

.form-group--inline .form-control,
.form-group select.form-control {
    border: none;
    border-bottom: 2px solid;
    padding-left: calc(7rem / 16);
    font-family: var(--font-default-bold);
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

.form-group--inline .form-control-label {
    padding-bottom: calc(9rem / 16);
    border-bottom: 2px solid;
    margin-bottom: 0;
}

.input-group--add-to-cart {
    flex-wrap: nowrap;
}

.input-group--add-to-cart .form-control {
    font-size: calc(16rem / 16);
    font-family: var(--font-default-bold);
    flex: 0;
    text-align: center;
    background-color: transparent;
}

.input-group--add-to-cart.input-group--big .form-control {
    width: calc(78rem / 16);
    height: calc(70rem / 16);
    min-width: calc(78rem / 16);

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        width: 60px;
    }
}

.cart-submit-group input[type="number"]::-webkit-inner-spin-button,
.cart-submit-group input[type="number"]::-webkit-outer-spin-button,
.input-group--add-to-cart input[type="number"]::-webkit-inner-spin-button,
.input-group--add-to-cart[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cart-submit-group .form-control {
    width: calc(50rem / 16);
    font-size: calc(16rem / 16);
    font-family: var(--font-default-bold);
    text-align: center;
    background-color: transparent;
    height: 100%;

    @media screen and (min-width: 768px) {
        width: calc(82rem / 16);
    }
}

.cart-submit-group .btn {
    height: 100%;

    @media screen and (max-width: 767px) {
        font-size: calc(10rem / 16);
    }
}

.cart-submit-group .btn-cart .btn-icon-text__icon {
    @media screen and (max-width: 767px) {
        font-size: calc(13rem / 16);
        margin-right: calc(5rem / 16);
    }
}

.form-errors {
    position: absolute;
    bottom: 0;
    font-size: calc(10rem / 16);
    left: calc(16rem / 16);
}

.custom-checkbox .form-errors,
.custom-radio .form-errors {
    left: 0;
}

.form-control-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0.625rem 0.9375rem;
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
}
.form-control-overlay__icon {
    margin-right: calc(5rem / 16);
    font-size: calc(26rem / 16);
}
.form-control-overlay__icon.icon-close {
    transform: rotate(45deg);
}
.form-control.has-value ~ .form-control-overlay,
.form-control:focus ~ .form-control-overlay {
    opacity: 0;
}

.form-control--cursor {
    cursor: pointer;
}
.form-control:focus {
    cursor: auto;
    border: 1px solid black;
}
ul.parsley-errors-list {
    margin-left: 0;
    padding-left: 0;
    margin-top: -0.25rem;
}
ul.parsley-errors-list li {
    list-style-type: none;
    color: var(--color-danger);
    font-size: 0.625rem;
}

.rating-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}
@media (min-width: 768px) {
    .rating-wrapper {
        grid-template-columns: repeat(8, 1fr);
    }
}
@media (min-width: 1025px) {
    .rating-wrapper {
        grid-template-columns: repeat(24, 1fr);
    }
    .col-sm-6 .rating-wrapper {
        grid-template-columns: repeat(12, 1fr);
    }
}
.rating-item {
    position: relative;
    text-align: center;
    cursor: pointer;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.rating-item input {
    z-index: -1;
    opacity: 0;
}
.rating-number {
    z-index: 3;
    position: relative;
}
.rating-heart {
    z-index: 2;
    font-size: 32px;
    color: var(--color-primary);
    top: 0;
    opacity: 0.6;
    margin-top: -26px;
    position: relative;
    display: block;
    opacity: 0;
}
.rating-radio {
    position: absolute;
}
.rating-active .rating-heart {
    opacity: 0.6;
}
.rating-input {
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    opacity: 0;
    border: 0 none;
}

.division-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
@media (min-width: 768px) {
    .division-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1025px) {
    .division-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
.consent-row .form-control-label {
    display: none;
}
