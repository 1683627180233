.img-teaser-grid-area{
    position: relative;
    padding-top: calc(64rem/16);
    padding-bottom: calc(64rem/16);
}

.img-teaser-grid-area:after{
    content: '';
    position: absolute;
    width: 70%;
    height: 20%;
    background-color: var(--color-light-grey);
    left: 0;
    z-index: -1;

    @media screen and (min-width: 768px){
        width: 50%;
        height: 67%;
        left: 30%;
        bottom: 0;
    }
    
    @media screen and (max-width: 767px){
        top: calc(40rem/16);
    }
}

.img-teaser-grid-area:nth-child(odd):after{
    top: 0;
    bottom: 0;
    left: calc(-49rem/16);
    right: calc(-49rem/16);
    width: auto;
    height: auto;
}


.img-teaser-grid-area__content{
    max-width: calc(400rem/16);
    width: 100%;
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 1400px){
        margin-bottom: calc(60rem/16);
    }
    
    @media screen and (min-width: 768px){
       min-height: calc(170rem/16);
    }
}