.per-page{
    display: flex;
    justify-content: flex-end;
}
.per-page-item + .per-page-item{
    margin-left: calc(10rem/16);
    padding-left: calc(10rem/16);
    border-left: solid calc(1rem/16) var(--color-default);
}
.per-page-item.is-active{
    font-family: var(--font-default-bold);
}