.filter-overview{
    position: relative;
}

.filter-overview-box-right:before{
    content: '';
    position: absolute;
    right: calc(-60rem/16);
    top: 0;
    width: 50%;
    height: 20%;
    min-height: calc(450rem/16);
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (min-width: 768px){
        width: 38%;
    }
}

.filter-overview-box-left:after{
    content: '';
    position: absolute;
    left: calc(-60rem/16);
    bottom: 22%;
    width: 65%;
    height: 20%;
    min-height: calc(450rem/16);
    background-color: var(--color-light-grey);
    z-index: -1;
}