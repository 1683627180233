.scroll-top{
    opacity: 0;
    display: none;
    transition-duration: .33s;
    transition-property: opacity;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    @media screen and (max-width: 767px) {
        bottom: 0;
        right: 0;
        width: 50px;
        height: 50px;
        background: var(--color-primary);
        border: none;
    }
}

.scroll-top::after{
    content: '';
    position: absolute;
    top: -10px;
    width: 30px;
    height: 60px;
    background-color: var(--color-primary);
    z-index: -1;
    opacity: .6;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.scroll-top.active{
    opacity: 1;
    display: flex;
}

.scroll-top__text{
    position: absolute;
    top: 0;
    transform: rotate(-90deg) translateX(calc(50% + 30px));
    transform-origin: 50% 50%;
}

