.nav-tabs--primary{
    border: none;
}

.nav-tabs--primary .nav-link{
    border:none;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    position: relative;
    opacity: 0.4;
    transition: all 250ms ease;
    margin: calc(8rem/16) calc(24rem/16);
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width:767px) {
        margin: calc(8rem/16) calc(10rem/16);
    }
}

.nav-tabs--primary>li:first-child .nav-link{
    margin-left: 0;
}

.nav-tabs--primary>li:last-child .nav-link {
    margin-right: 0;
}

.nav-tabs--primary .nav-link.active,
.nav-tabs--primary .nav-link:hover{
    opacity: 1;
    background-color: transparent;
}

.nav-tabs--primary .nav-link:before{
    content: '';
    position: absolute;
    height: calc(3rem/16);
    left: 0;
    bottom: calc(3rem/16);
    width: 100%;
    background-color: var(--color-default);
    transform: scale(0,1);
    transition: all 250ms ease;
}


.nav-tabs--primary>li:first-child .nav-link:before{
    left: 0;
}

.nav-tabs--primary .nav-link.active:before{
    transform: scale(1);

}

@media screen and (max-width: 767px) {
    .nav-tabs--scroll-mobile {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
    }

    .nav-tabs--scroll-mobile .nav-item{
        display: inline-block;
    }

    .nav-tabs--scroll-mobile .nav-link{
        white-space: nowrap;
    }
}

.tab-slider .nav-link {
    line-height: initial;
}
.tab-slider .slick-slide {
    opacity: .3;
    transition: opacity 200ms ease-in-out;
}
.tab-slider .slick-slide.slick-active {
    opacity: 1;
}
.tab-slider .slider__arrow {
    left: calc(-20rem / 16);
}
.tab-slider .slider__arrow.slick-next {
    left: auto;
    right: calc(-20rem / 16);
}
.tab-slider .slider__arrow__icon {
    transform: rotate(-90deg);
}
.tab-slider .slick-prev .slider__arrow__icon {
    transform: rotate(90deg);
}