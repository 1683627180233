.table--responsive thead{
@media screen and (max-width: 767px) {
    display: none;
}
}
.table--responsive tr{
@media screen and (max-width: 767px) {
    border-bottom: 1px solid #c2c2c2;
}
}
.table--responsive td, .table--responsive th{
@media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 3px 15px;
    text-align: right;
    width: auto;
}
}
.table--responsive tr>td:first-child{
@media screen and (max-width: 767px) {
    padding-top: 15px;
}
}

.table.table--cart tbody>tr{
    background-color: transparent;
}

.table.table--cart tbody>tr.bg-light-grey {
    background-color: var(--color-dark-grey);
}

.table__select{
    padding: 0 !important;
    vertical-align: top;
}

.table__select .custom-radio{
    height: 75px;
}
.table__select label{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 75px;
    width: 75px;
}
.table__select-line{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    background-color: #000;
    opacity: 0;
}
.table__select-line:after{
    content: '';
    z-index: -1;
    position: absolute;
    width: 400%;
    height: 95%;
    background-color: var(--color-primary);
    transform: translateX(-15px) translateY(15px)
}
.custom-radio__input:checked ~ .table__select-line{
    opacity: 1;
}
.table__select .custom-radio__box{
    transform: translateX(30px);
}
.table__select .custom-radio__active-box{
    transform: translateX(30px)  translateY(30px);;
}

.table-simple td,
.table-simple th{
    @media screen and (min-width: 768px){
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
    }
}

@media screen and (min-width: 768px) {
    .table.table--no-action tr td,
    .table.table--no-action tr th {
        padding-right: 1.875rem;
    }
}

.table-gray {
    width: 100%;
}

.table-gray tr:hover td {
    background: white;
}

.table-gray th, .table-gray td {
    padding: 0.25rem;
    background: var(--color-light-grey);
}