.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-overlay.loading-overlay--product-grid{
    @media screen and (min-width: 768px){
        top: calc(-50rem/16);
    }
}