.cutout-input__number {
    font-size: 0.875rem;
    text-align: right;
    width: 3rem;
    border: 0 none;
    z-index: 2;
    position: relative;
    -moz-appearance: textfield;
}
.cutout-input__number::-webkit-inner-spin-button,
.cutout-input__number::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.cutout-input__units {
    font-size: 0.75rem;
    padding-left: 0.25rem;
    position: relative;
    z-index: 1;
    transform: translateX(-1rem);
}
.cutout-display-value {
    font-size: 0.75rem;
}


.cutout-wrapper {
    display: flex;
    align-items: center;
}

#slider-range {
    flex-grow: 1;
    height: 1px;
    border: 0 none;
    background-color: var(--color-primary);
}

.noUi-horizontal .noUi-handle {
    border-radius: 9000px;
    width: 16px;
    height: 16px;
    background-color: var(--color-primary);
    border: 0 none;
    right: -8px;
    top: -8px;
}

.noUi-handle:after, .noUi-handle:before {
    display: none;
}

.noUi-connect {
    border: 0 none;
    height: 1px;
    background-color: black;
}

.cutout-min-wrapper {
    padding-right: 1rem;
}
.cutout-max-wrapper {
    padding-left: 1rem;
}
