.state-circle {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border-radius: 50%;
    position: relative;
    color: #fff;
    font-size: calc(24rem / 16);
}
.state-circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.state-circle--success {
    background: var(--color-success);
}
.state-circle--error {
    color: var(--color-default);
    border: 1px solid var(--color-default);
}
.state-circle__icon.icon-notice {
    font-size: calc(34rem / 16);
}