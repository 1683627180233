.project-gallery-grid__gallery-wrapper {
    margin-top: 50px;
    display: block;
}
.project-gallery-grid__img-wrapper {
    position: relative;
    justify-content: center;
    align-items: center;
}
.project-gallery-grid__img-overlay{
    position: absolute;
    color:white;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition:opacity .4s ease-in;
}
.not-loaded-yet{
    width: 0;
    height: 0;
}
.project-gallery-grid__img-wrapper:hover .project-gallery-grid__img-overlay{
    opacity: 0.7;
}

.product-family-links{
    z-index: 1;
    text-align: center;
    position: absolute;
    font-size: 1rem;
    opacity: 0;
    transition: all .4s ease-in;
    color: whitesmoke;
    width:max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.project-gallery-grid__img-wrapper:hover .product-family-links{
    opacity: 1;
}
.project-gallery-grid > div img {
    width: 100%;
    height: auto;
}

.project-gallery-grid__img-wrapper img{
    opacity: 0;
}

.project-gallery-grid__img-wrapper[data-macy-complete="1"] img{
    opacity: 1;
}

.project-gallery-grid__img-wrapper:nth-child(2),
.project-gallery-grid__img-wrapper:nth-child(4){
    overflow: hidden;
}

.project-gallery-grid__img-wrapper:nth-child(2) img,
.project-gallery-grid__img-wrapper:nth-child(4) img
 {
    padding-top: 1.25rem;
}

.project-gallery-grid__img-wrapper:nth-child(2) .project-gallery-grid__img-overlay,
.project-gallery-grid__img-wrapper:nth-child(4) .project-gallery-grid__img-overlay
 {
    margin-top: 1.25rem;
}

.project-gallery-grid__img-wrapper:nth-child(2) .product-family-links,
.project-gallery-grid__img-wrapper:nth-child(4) .product-family-links
 {
    margin-top: 0.75rem;
}

.hotspot{
    background-color:var(--color-light-grey);
    z-index: -1;
    position: absolute;
    top: 50px;
    width:500px;
    height:600px;
}
