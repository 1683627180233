.btn-primary:focus {
    box-shadow: 0 0 0 .2rem var(--color-black);
}
.btn-primary {
    background: white;
    border-color: var(--color-black);
}
.btn-primary:hover {
    box-shadow: 0 0 0 .2rem var(--color-black);
    color: var(--color-black);
    background-color: var(--color-primary);
    border-color: var(--color-primary) !important;
}
.btn-outline{
    border: 1px solid var(--color-text-default);
    color: var(--color-text-default);
    transition: all 300ms ease;
}

.btn-outline:hover{
    background-color: var(--color-primary);
    color: var(--color-black);
    border-color: var(--color-primary);
}

.btn-outline-white{
    border: 1px solid var(--color-white);
    color: var(--color-white);
}

.btn-outline-white:hover{
    background-color: #fff;
    color: var(--color-text-default);
}

.btn-outline-inverted {
    background-color: var(--color-primary);
    color: var(--color-black);
    border-color: var(--color-primary);
    transition: all 300ms ease;
}

.btn-outline-inverted:hover {
    background-color: var(--color-black);
    border: 1px solid var(--color-text-default);
    color: var(--color-text-default);
}

.btn-no-style{
    background-color: transparent;
    border: none;
    padding: 0;
    color: inherit;
}

.btn-icon{
    line-height: 1;
}

.btn-icon>.icon{
    vertical-align: calc(-2rem/16);
}

.btn-icon-text__icon{
    vertical-align: calc(-2rem/16);
    margin-right: calc(10rem/16);
}

.btn-icon-text__icon--right{
    margin-right: 0;
    margin-left: calc(5rem/16);
}

.btn.btn-cart{
    padding: calc(5rem/16) calc(15rem/16);

    @media screen and (min-width: 768px) and (max-width: 1450px){
        font-size: calc(12rem/16);
    }

    @media screen and (max-width: 767px){
        padding: calc(5rem/16) calc(12rem/16);
    }
    
}

.btn.btn-cart .btn-icon-text__icon{
    vertical-align: calc(-3rem/16);
    margin-right: calc(10rem/16);
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px) and (max-width: 1450px){
        font-size: calc(15rem/16);
    }
}

.btn-list>.list-inline-item:not(:last-child){
    margin-right: calc(15rem/16);
    @media screen and (min-width: 768px){
        margin-right: calc(25rem/16);
    }
}

.btn-list{
    margin-top: calc(-10rem/16);
}
.btn-list>.list-inline-item{
    margin-top: calc(10rem/16);
}

.input-group--teaser .btn-cart{
   @media screen and (max-width: 767px){
      font-size: calc(12rem/16);
   }
}

.input-group--teaser .btn.btn-cart .btn-icon-text__icon{
   @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
       margin-right: calc(5rem/16);
       vertical-align: calc(-2rem/16);
   }
}

/* 
.btn-secondary {
    color: black;
    background-color: white;
} */

.btn-secondary:hover {
    color: white;
    background-color: black;
}




.btn-arrow{
    background-color: transparent;
    border: none;
    line-height: 1;
    position: relative;
    z-index: 1;
    bottom: 0;
    font-size: calc(12rem/16);
    padding: calc(9rem/16) calc(5rem/16);
}
.btn-arrow:hover, .btn-arrow:active, .btn-arrow:focus{
    background-color: #fff;
}.btn-arrow:hover:before, .btn-arrow:active:before, .btn-arrow:focus:before{
     width: 3.25rem;
 }
.btn-arrow:before{
    content: '';
    position: absolute;
    width: calc(24rem/16);
    height: calc(30rem/16);
    background-color: var(--color-primary);
    top: 0;
    transition-duration: .33s;
    transition-property: width;
}
.btn-arrow:disabled, .btn-arrow.btn--disabled{
     opacity: 0.3;
}

.btn-arrow.btn-arrow--prev:before{
    left: calc(-2rem/16);
}

.btn-arrow.btn-arrow--next{
    right: 0;
}

.btn-arrow.btn-arrow--next:before{
    right: calc(-2rem/16);
}

.product-request__btn {
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
}

.product-request__btn .icon {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}

.icon-btn {
    width: calc(43rem / 16);
    height: calc(43rem / 16);
    padding: calc(5rem / 16);
    position: relative;
}
.icon-btn__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/* copy btn */
.copy-btn {
    position: relative;
}
.copy-btn__active,
.copy-btn__default {
    transition: opacity 300ms ease-in-out;
}
.copy-btn__active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
}
.is-active .copy-btn__active {
    opacity: 1;
}
.is-active .copy-btn__default {
    opacity: 0;
}

.read-more-container {
    text-align: center;
}
.read-more-btn {
    margin-top: 3rem;
}
.read-more-btn-text {
    font-family: var(--font-default);
    color: var(--color-primary);
    font-weight: 400;
}
.custom-read-more-icon {
    color: var(--color-primary);
}