.modal-body{
    @media screen and (min-width: 768px){
       padding: calc(50rem/16) calc(60rem/16);
    }
}

.modal-body__close.close{
    position: absolute;
    top: calc(3rem/16);
    right: 0;
    padding: calc(6rem/16);
    font-size: 38px;
}

.modal--no-backdrop {
    background: rgba(0, 0, 0, 0.15);
}

.modal--no-backdrop .modal-content {
    box-shadow: 0 0 2rem -0.5rem rgba(0, 0, 0, 0.7);
}

.model-content__placeholder {
    min-height: calc(250rem / 16);
}

.modal-body--scrolling {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal-body--scrolling::-webkit-scrollbar {
    width: calc(6rem/16)
}
.modal-body--scrolling::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}
.modal-body--scrolling::-webkit-scrollbar-thumb {
    background: #d3d3d3;
}