.hotspots__container{
    position: relative;
}

.hotspots__hotspot{
    position: absolute;
    z-index: 5;
}

.hotspots__hotspot.is-open{
    z-index: 6;
}

.hotspots__btn{
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: calc(18rem/16);
    height: calc(18rem/16);
    position: relative;
    transition: all 250ms ease;
    display: block;
    padding: 0;
}

.hotspots__btn:hover{
    background-color: var(--color-primary);
}
.hotspots__btn:after{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    background-color: transparent;
    opacity: 1;
    display: block;
    animation: pulse 2s infinite ;
    animation-delay: 1s;
}
/*.hotspots__btn:hover:after{
    display: block;
    opacity: 1;
    animation: pulse 2s infinite ;
    animation-delay: 1s;
}*/
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.hotspots__detail{
    background-color: #fff;
    display: none;

    @media screen and (min-width: 768px){
        width: calc(335rem/16);
        position: absolute;
        right: calc(-15rem/16);
        top: calc(-5rem/16);
    }
}

.hotspots__detail.is-open{
    display: block;
}

.hotspots__detail-close{
    position: absolute;
    right: 0;
    top: 0;
    font-size: calc(32rem/16);
    padding: calc(6rem/16);
    
    @media screen and (max-width: 767px){
       display: none;
    }
}

.hotspots__hotspot--right .hotspots__detail{
    right: auto;
    left: 0;
}

.hotspots__hotspot--top .hotspots__detail{
    top: auto;
    bottom: 0;
}

.hotspots__detail-title{
    font-size: calc(20rem/16);
}