.anchor-nav{
    margin-top: calc(20rem/16);
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(-20rem/16);
    }
}

.anchor-nav .nav{
    justify-content: space-evenly;
}

.anchor-nav .nav-link{
    font-size: calc(15rem/16);
    line-height: calc(26/22);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    position: relative;
    padding: calc(5rem/16) 0;

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(10rem/16);
    }
}

.anchor-nav .nav-link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: calc(4rem/16);
    background-color: var(--color-default);
    transition: all 250ms ease;
    transform: translateX(-50%) scale(0,1);
}

.anchor-nav .nav-link:hover:after{
    transform: translateX(-50%) scale(1,1);
}