.gallery-slider__wrapper {
    margin-left: calc(220rem/16);
    padding-bottom: calc(60rem/16);

    @media screen and (max-width: 1199px) {
        margin-left: 0;
        padding-left: calc(32rem/16);
        padding-right: calc(32rem/16);
        width: 100%;
    }
}

.gallery-slider__wrapper .title-block__title {
    @media screen and (min-width: 1200px) {
        margin-left: -20%;
    }
    @media screen and (min-width: 1480px) {
        margin-left: -13.5rem;
    }
}

.gallery-slider {
    position: relative;
    margin-top: calc(10rem/16);
    padding-top: calc(55rem/16);
}
.gallery-slider:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-60rem/16);
    height: 80%;
    width: 62%;
    background-color: var(--color-light-grey);
    z-index: -1;
}

.gallery-slider__image:not(:first-child) {
    margin-top: calc(32rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}

.gallery-slider .slider__arrow-styled {
    transform: translateY(150%) translateX(-500%);

    @media screen and (max-width: 1200px) {
        transform: translateY(150%) translateX(0);
    }
}

.gallery-slider .slick-slide {
    @media screen and (min-width: 1200px) {
        margin-right: calc(30rem/16);
    }
}

/*hover states*/

.gallery-slider__image {
    position: relative;
}
.gallery-slider__image .gallery-slider__image-overlay {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    color: var(--color-white);
    font-size: calc(40rem/16);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s ease;
}

.gallery-slider__media {
    transform: scale(1);
    transition: transform 0.3s ease;
}



@media (hover: hover) {
    .gallery-slider__image:hover .gallery-slider__image-overlay {
        opacity: 1;
    }

    .gallery-slider__image:hover .gallery-slider__media {
        transform: scale(1.1);
        transition: transform 0.4s ease;
    }

    .gallery-slider__image.gallery-slider__image--square-big:hover .gallery-slider__media{
        transform: scale(1.1) translateX(-50%);
    }
}


.gallery-slider__image--square-big{
    @media screen and (min-width: 768px){
        height: 100%;
    }
}

.gallery-slider__image--tall-big{
    height: 100%;

}


.gallery-slider .slick-slide{
    @media screen and (max-width: 767px){
       margin: 0 calc(8rem/16);
    }
}