.popover{
    box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.72);
    border: none;
}
.popover-header{
    background: transparent;
    border-bottom: none;
}

.popover .arrow{
    display: none;
}