.teaser-area {
    position: relative;

    @media screen and (min-width: 768px){
        padding-bottom: calc(60rem/16);
    }
}
.teaser-area:after {
    content: '';
    position: absolute;
    left: 15%;
    top: 30%;
    width: 52%;
    height: 70%;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (max-width: 767px) {
        height: 60%;
        width: 65%;
    }
}

.teaser-area__teaser-overlay{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    color: #fff
}


.teaser-area__teaser--smaller{
    margin-top: 5rem;
    @media screen and (max-width: 991px) {
        margin-top: 3rem;
    }
}
.teaser-area__teaser-text {
    margin-bottom: 0;
    word-break: break-word;
}
.teaser-area__teaser-text.teaser-area__teaser-text--smaller{
    @media screen and (max-width: 991px) {
        font-size: 1.5em;
    }
    @media screen and (max-width: 575px) {
        font-size: .85em;
    }
}

.img-teaser-slider{
    @media screen and (max-width: 767px){
       padding-bottom: calc(20rem/16);
    }
}

.img-teaser-slider .slick-list{
    margin: 0 -15px;
    padding: 50px 0;
}

.img-teaser-slider .slick-slide{
    margin-left:  15px;
    margin-right:  15px;
    height: inherit !important;
}

.img-teaser-slider .slick-track {
    line-height: 0;
    display: flex !important;
}

.img-teaser-slider .img-teaser__body{
    line-height: calc(22/14);
}


.img-teaser-slider .slick-slide:nth-child(3n + 2) > div{
    height: 100%;
    display: flex;
    align-items: flex-end;
}
