.footer__title{
    font-family: var(--font-default);
    font-size: calc(24rem/16);
    line-height: calc(48/40);
    color: var(--color-primary);
    /* text-transform: lowercase; */
    
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(36rem/16);
    }
}

.footer-social{
    display: flex;
    flex: 1;
    align-items: center;
    padding: calc(35rem/16) 0 calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(50rem/16) 0;
    }
}

.footer-social__line{
    height: calc(2rem/16);
    background-color: var(--color-default);
    flex: 1;

    @media screen and (min-width: 768px){
        height: calc(1rem/16);
    }
}

.footer-social__item.list-inline-item{
    font-size: calc(30rem/16);
    margin-right: calc(15rem/16);
    
    @media screen and (min-width: 768px){
        margin-right: calc(90rem/16);
    }
}

.footer-social__item.list-inline-item:first-child{
    margin-left: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(70rem/16);
    }
}

.footer-social__item.list-inline-item:last-child{

    @media screen and (min-width: 768px){
        margin-right: calc(70rem/16);
    }
}

.footer-main{
    padding: calc(15rem/16) 0 calc(30rem/16);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) 0 calc(50rem/16);
    }
}

.footer-link-list{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.footer-link-list>li:not(:last-child){
    margin-bottom: calc(30rem/16);
}

.footer__newsletter{
    width: calc(300rem/16);
    margin: 0 auto;
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(30rem/16);
    }
}

.footer__newsletter .input-group-append{
    margin-left: calc(5rem/16);
}

.footer__newsletter-title{
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(20rem/16);
    }
}

.footer__arrow{
    font-size: calc(50rem/16);
}

footer .icon {
    color: var(--color-secondary);
}

.footer-xalgroup {
    padding: calc(35rem/16) 0 0;

    @media screen and (min-width: 768px){
        padding: calc(50rem/16) 0 0;
    }
}
.footer-xalgroup {
    width: 100%;
    text-align: center;
}
.footer-xalgroup .footer-social__line {
    /* height: .12rem !important; */
    margin-bottom: 2.3rem;
}

.footer-xalgroup .footer-link-list {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    display: inline-block;
    vertical-align: bottom;
    line-height: 1;
}
.footer-xalgroup .xalgroup-logo {
    vertical-align: bottom;
    display: inline-block;
    width: 4rem;
    /* height: 3rem; */
    margin-left: 1em;
    margin-top: -3px;
}