.img-teaser{
    position: relative;
    color: #fff;
}

.img-teaser:before{
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 40%;
}

.img-teaser__body{
    position: absolute;
    bottom: 0;
    padding: calc(10rem/16);
    z-index: 2;
    color: #fff;

    @media screen and (min-width: 768px){
        padding: calc(20rem/16);
    }

    @media screen and (min-width: 1200px){
        padding: calc(30rem/16);
    }
}

.img-teaser-grid-area .img-teaser + .img-teaser{
    @media screen and (max-width: 767px){
       margin-top: 1.5rem;
    }
}

.img-teaser__img{
    transition: transform 350ms ease;
}

.img-teaser:hover .img-teaser__img{
    transform: scale(1.09);
}

.img-teaser__title{
    word-break: break-word;
}

.img-teaser__title,
.img-teaser__title.h2{
    font-size: responsive calc(20rem/16) calc(24rem/16); /* min-size, max-size */
    font-range: calc(992rem/16) calc(1920rem/16); /* viewport widths between which font-size is fluid */

    margin-bottom: 0;
}

.img-teaser--area .img-teaser__title,
.img-teaser--area .img-teaser__title.h2{
    font-size: responsive calc(20rem/16) calc(28rem/16); /* min-size, max-size */
    font-range: calc(992rem/16) calc(1920rem/16); /* viewport widths between which font-size is fluid */
}

.img-teaser--big .img-teaser__title,
.img-teaser--big .img-teaser__title.h2{

    @media screen and (min-width: 768px){
        font-size: responsive calc(28rem/16) calc(35rem/16); /* min-size, max-size */
        font-range: calc(992rem/16) calc(1920rem/16); /* viewport widths between which font-size is fluid */
    }

}