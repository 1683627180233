.slider__arrow{
    background-color: transparent;
    border: none;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.slider__arrow.slick-prev{
    left: 0;
}

.slider__arrow.slick-next{
    right: 0;
}

.slider__arrow.slick-disabled{
    opacity: 0;
    cursor: default;
}

/* Arrows Styled */

.slider__arrow-styled{
    background-color: transparent;
    border: none;
    line-height: 1;
    position: absolute;
    z-index: 1;
    bottom: 0;
    font-size: calc(12rem/16);
    padding: calc(9rem/16) calc(5rem/16);
    color: inherit;
}

.slider__arrow-styled:before{
    content: '';
    position: absolute;
    width: calc(24rem/16);
    height: calc(30rem/16);
    background-color: var(--color-primary);
    top: 0;
}

.slider__arrow-styled.slick-disabled{
    opacity: 0.3;
}

.slider__arrow-styled.slick-prev{
    right: calc(58rem/16);
}

.slider__arrow-styled.slick-prev:before{
    left: calc(-2rem/16);
}

.slider__arrow-styled.slick-next{
    right: 0;
}

.slider__arrow-styled.slick-next:before{
    right: calc(-2rem/16);
}


/* Teaser Slider */
.teaser-slider .slider__arrow{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }
    
    @media screen and (max-width: 767px){
        padding: calc(7rem/16) calc(10rem/16);
    }
}

.teaser-slider .slider__arrow.slick-prev{
    left: calc(-15rem/16);

    @media screen and (min-width: 1470px){
        left: calc(-25rem/16);
    }

    @media screen and (max-width: 767px){
        padding-right: calc(12rem/16);
    }
}

.teaser-slider .slider__arrow.slick-next{
    right: calc(-15rem/16);

    @media screen and (min-width: 1470px){
        right: calc(-25rem/16);
    }

    @media screen and (max-width: 767px){
        padding-left: calc(12rem/16);
    }
}

.circle-img-teaser-slider .teaser-slider .slider__arrow.slick-next{
    @media screen and (max-width: 767px){
        right: calc(-25rem/16);
    }
}

.circle-img-teaser-slider .teaser-slider .slider__arrow.slick-prev{
    @media screen and (max-width: 767px){
        left: calc(-25rem/16);
    }
}

    /* Circle Img TEaser Slider */

.circle-img-teaser-slider .slider__arrow{
    @media screen and (min-width: 768px){
        margin-top: calc(-18rem/16);
    }
}

/* Dots */
.slider--hide-dots .slick-dots{
    display: none!important;
}

/* product teaser slider */

.product-teaser-slider .teaser-slider{
    padding-bottom: calc(50rem/16);
}


.slick-slider:not(.js-wysiwyg-slider) {
    line-height: 0;
}
.slick-slider.slick-slider--1 > div {
    display: none;
}
.slick-slider.slick-slider--1 > div:first-child {
    display: block;
}
.slick-slider.slick-slider--1.slick-initialized > div {
    display: block;
}


.wysiwyg-slide {
    width: 100%;
}

.wysiwyg-slider .slick-slide {
    overflow: hidden;
}

.wysiwyg-arrows  .slider__arrow-styled {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    transform: none;
}

.wysiwyg-arrows .slick-arrow {
    display: inline-block;
}

.wysiwyg-slider .content-block {
    @media screen and (max-width: 767px) {
        /* padding-left: 2rem;
        padding-right: 2rem; */
    }
}

.wysiwyg-arrows {
    position: absolute;
    bottom: calc(50rem/16);
    left: 0;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    width: 100%;
    @media screen and (max-width: 767px) {
        position: relative;
        bottom: auto;
        top: auto;
        left: auto;
        right: auto;
        transform: none;
        margin-top: calc(20rem/16);
        text-align: left !important;
        padding-bottom: 2rem;
        padding-left: 0;
        padding-right: 0;
    }
}

.wysiwyg-is-right .wysiwyg-arrows {
    right: 0;
    text-align: right;
}