.lightbox-img{
    position: relative;
    display: block;
    overflow: hidden;
}

.btn-lightbox{
    position: absolute;
    right: calc(-18rem/16);
    bottom: calc(-18rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid #fff;
    content: '';
    opacity: 0;
    transform: scale3d(0.65,0.65,1);
    transform-origin: bottom right;
    transition: opacity 0.35s, transform 0.35s;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 0;
    color: #fff;
    z-index: 2;
}

.btn-lightbox .icon{
    position: relative;
    top: calc(-4rem/16);
    left: calc(-7rem/16);
    font-size: calc(15rem/16);
}

.lightbox-img:hover .btn-lightbox {
    opacity: 1;
    transform: scale3d(1,1,1);
}

/* hover state */
@media screen and (min-width: 768px) {
    .lightbox-img .lightbox-img__image-overlay {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        color: var(--color-white);
        font-size: calc(40rem/16);
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s ease;
    }
    .lightbox-img:hover .lightbox-img__image-overlay {
        opacity: 1;
    }
    .lightbox-img__image {
        transform: scale(1);
        transition: transform 0.3s ease;
    }
    .lightbox-img:hover .lightbox-img__image {
        transform: scale(1.1);
        transition: transform 0.4s ease;
    }
}

.lightbox-caption{
    display: none;
}

/* lightgallery styling */

.lg-backdrop{
    background-color: #fff;
}

.lg-toolbar{
    background-color: #fff;
}

.lg-toolbar .lg-icon{
    background-color: #f3f3f3;
    color: var(--color-default);
    opacity: 1;
}

.lg-sub-html,
.lg-actions .lg-next, .lg-actions .lg-prev{
    background-color: #f3f3f3;
    color: var(--color-default);
}

.lg-actions .lg-prev,
.lg-actions .lg-next{
    background-color: transparent;
    font-size: calc(13rem/16);
}

.lg-actions .lg-prev:after{
    content: var(--icon-arrow-long-left);
    font-family: iconfont;
}

.lg-actions .lg-next:before{
    content: var(--icon-arrow-long-right);
    font-family: iconfont;
}

.lg-actions .lg-prev:before{
    content: '';
    position: absolute;
    width: calc(24rem/16);
    height: calc(30rem/16);
    top: 0;
    left: 0;
    background-color: var(--color-primary);
    z-index: -1;
}

.lg-actions .lg-next:after{
    content: '';
    position: absolute;
    width: calc(24rem/16);
    height: calc(30rem/16);
    top: 0;
    right: 0;
    background-color: var(--color-primary);
    z-index: -1;
}

.lg-toolbar .lg-icon.lg-close{
    font-size: calc(34rem/16);
}
.lg-toolbar .lg-close:after{
    content: var(--icon-close);
    font-family: iconfont;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover,
.lg-toolbar .lg-icon:hover{
    color: var(--color-default);
}

.lightbox-video__video{
    height: 100%;
    width: 100%;
    transition-property: transform;
    transition-duration: .33s;
}
.lightbox-video:hover .lightbox-video__video{
    transform: scale(1.1);
}

.reference-box {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 0.25rem 0.75rem;
    line-height: 1.25;
}

.reference-box a {
    text-decoration: underline;
}