.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a:not(.btn) {
    color: var(--color-default);
    font-family: var(--font-default-bold);
    text-decoration: underline;
}
.wysiwyg a:hover:not(.btn),
.wysiwyg a:focus:not(.btn),
.wysiwyg a:active:not(.btn) {
    color: var(--color-secondary);
    text-decoration: underline;
}

.wysiwyg ul{
    list-style: none;
}

.wysiwyg ul>li{
    padding-left: 0;
    position: relative;
    margin-bottom: calc(10rem/16);
}

.wysiwyg ul>li:before{
    content: "";
    color: var(--color-secondary);
    line-height: 1;
    display: inline-block;
    width: calc(8rem/16);
    height: calc(8rem/16);
    background: var(--color-default);
    margin-left: calc(-8rem/16);
    left: calc(-10rem/16);
    top: calc(-1rem/16);
    position: relative;
}

.text-center .wysiwyg ul>li:before{
    width: calc(20rem/16);
    margin-left: calc(-20rem/16);
}

/* wysiwyg-area */

.wysiwyg-area{
    padding-top: calc(40rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(55rem/16);
    }
}
.wysiwyg-area--none{
    padding-top: calc(40rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(55rem/16);
    }
}

.wysiwyg-area:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 30%;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (min-width: 768px){
        left: calc(-120rem/16);
        width: 22.5%;
        height: 65%;
    }
}

.wysiwyg-area.wysiwyg-area--big-box:before{
    width: 60%;

    @media screen and (min-width: 768px){
        width: 48%;
    }
}
.wysiwyg-area--none.wysiwyg-area--big-box:before{
    width: 60%;

    @media screen and (min-width: 768px){
        width: 48%;
    }
}
.wysiwyg-area__content{
    @media screen and (min-width: 768px){
       padding-left: calc(120rem/16) !important;
    }
}

/* wysiwyg with medium */

.wysiwyg-with-medium__body{
    z-index: 2;
    position: relative;
}

.wysiwyg-is-right .wysiwyg-with-medium__body{
    
    @media screen and (min-width: 768px){
        padding-left: calc(42rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-top: calc(-50rem/16);
    }
}

.wysiwyg-is-left .wysiwyg-with-medium__body{    
    @media screen and (min-width: 768px){
        padding-right: calc(42rem/16);
    }
    @media screen and (max-width: 767px){
       margin-top: calc(-50rem/16);
    }
}

.wysiwyg-with-medium__media{
    position: relative;
    padding-bottom: calc(50rem/16);
}

.wysiwyg-with-medium__media--none{
    position: relative;
    padding-bottom: calc(50rem/16);
}

.wysiwyg-with-medium__media:before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 90%;
    height: 90%;
    background-color: var(--color-light-grey);
}

.wysiwyg-is-right .wysiwyg-with-medium__media:before{
    left: calc(-60rem/16);
}

.wysiwyg-is-left .wysiwyg-with-medium__media:before{
    right: calc(-60rem/16);
}
.wysiwyg-slider .row {
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.wysiwyg-slider .container.container {
    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
    }
}
/*hover states*/
@media screen and (min-width: 1200px) {
    .wysiwyg-with-medium__media .wysiwyg-with-medium__image-overlay {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        color: var(--color-white);
        font-size: calc(40rem/16);
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s ease;
    }
    .wysiwyg-with-medium__media:hover .wysiwyg-with-medium__image-overlay {
        opacity: 1;
    }
    .wysiwyg-with-medium__media-img {
        transform: scale(1);
        transition: transform 0.3s ease;
    }
    .wysiwyg-with-medium__media:hover .wysiwyg-with-medium__media-img {
        transform: scale(1.1);
        transition: transform 0.4s ease;
    }
}

.wysiwyg-with-medium--grey-background {
    padding-top: 0;
    background: #f3f3f3;
}

.wysiwyg-with-medium--white-background {
    padding-top: 0;
    background: white;
}
.wysiwyg-slider .content-block {
    padding-top: 2rem;
}

/* table styling in _tables.scss */