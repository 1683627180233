.product-price{
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
}

.product-status{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}

.product-status__circle{
    width: calc(13rem/16);
    height: calc(13rem/16);
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--color-dark-grey);
    display: inline-block;
    vertical-align: calc(-2rem/16);
}

.product-status__circle.status-available, .product-status__circle.status-few-available{
    background-color: var(--color-success);
}

.product-status__circle.status-not-available{
    background-color: var(--color-danger);
}

.product-status__circle.status-stock-incoming,
.product-status__circle.status-stock-incoming-tbc {
    background-color: var(--color-warning);
}