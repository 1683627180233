:root {
    --color-primary: #ffffff;  /*default: #D5E7C8 */
    --color-secondary: #6c757d;
    --color-text-default: #1D1D1B;
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #F3F3F3;
    --color-dark-grey: #D0D0D0;
    --color-dark: #343A40;

    --color-success: #7ED321;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #1D1D1B;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #1D1D1B;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #bcceb0; /*default: #bcceb0*/
    --color-secondary-dark: #424D56;
    --color-success-dark: #61ae1f;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #000;

    --color-primary-light: #ffffff;
    --color-secondary-light: #6E8090;
    --color-success-light: #88e223;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #454543;

    --font-default: "Gotham-Book", sans-serif;
    --font-default-bold: "Gotham-Bold", sans-serif;
    --font-tungsten: "Tungsten-Semibold", sans-serif;
    --font-chronicle: "Chronicle-Text", sans-serif;

    --font-size-default: calc(14rem/16);
}
