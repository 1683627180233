/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: calc(5rem/16);
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
video {
    background: #000000;
}


/* PAGE LOAD FIX
    once the page and all css assets/fonts are loaded give it opacity: 1, ajust transition time as you please
    if you want to hide everything, since the logo sometimes jumps, remove .main-content
*/
body .main-content {
    opacity: 0;
    transition: 0.15s ease-in-out;
}
body.loaded .main-content {
    opacity: 1;
}

.bg-\[\#F5F2ED\], .bg-\[\#f5f2ed\] {
    background-color: var(--color-light-grey) !important;
}

body.darkmode {
    /* --color-primary: yellow; */
    --color-text-default: #ffffff;
    --color-default: #ffffff;
    background: black;
    --color-bg-dark: black;
    --color-light-grey: black;
    --color-secondary: #ffffff;
}

.darkmode .navbar {
    background-color: var(--color-bg-dark);
}
.darkmode .navbar-right__item-icon path {
    fill: white;
}

.darkmode .navbar-nav__subnav {
    background-color: var(--color-bg-dark);
}

.darkmode .navbar-right__cart-count {
    color: black !important;
}

.darkmode .navbar-brand img {
    filter: invert(1);
}

.darkmode .xalgroup-logo {
    filter: invert(1);
}

.darkmode .navbar-toggler {
    background-color: black;
}

.darkmode .navbar-collapse {
    background-color: black;
}

.hidden {
    display: none;
}