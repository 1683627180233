@media screen and (min-width: 768px) and (max-width: 1199px) {
    .js-hero-teaser-slider .slick-list {
        margin: 0 -5%;
    }
    .js-hero-teaser-slider .slick-slide > div {
        padding: 0 5%;
    }
}

@media screen and (min-width: 768px) {
    .js-hero-teaser-slider.hero-teaser--big-slides .slick-list {
        margin: 0 -6%;
    }
    .js-hero-teaser-slider.hero-teaser--big-slides .slick-slide > div {
        padding: 0 6%;
    }
    .js-hero-teaser-slider .slick-slide {
        display: flex;
        justify-content: center !important;
    }
}

.js-hero-teaser-slider .slick-dots {
    position: absolute;
    left: 0;
    display: flex !important;
    width: 75%;
    list-style: none;
    padding-inline-start: calc(0.5rem + 30%) !important;
    bottom: -3.25rem;
}
@media screen and (min-width: 768px) {
    .js-hero-teaser-slider.hero-teaser--big-slides .slick-dots {
        padding-inline-start: calc(1rem + 25%) !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .js-hero-teaser-slider .slick-dots {
        padding-inline-start: calc(0.75rem + 35%) !important;
    }
}
.js-hero-teaser-slider .slick-dots li {
    margin: 0 5px;
}

.js-hero-teaser-slider .slick-dots li button {
    padding: 0px !important;
    position: relative;
    width: 15px !important;
    height: 15px !important;
    border-radius: 100%;
    border: none;
    transition: background-color 0.3s ease;
    overflow: hidden;
}

.js-hero-teaser-slider .slick-dots li button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px !important;
    height: 15px !important;
    border-radius: 100%;
    background-color: var(--color-primary);
    opacity: 0.3;
}

.js-hero-teaser-slider .slick-dots li.slick-active button {
    background-color: var(--color-primary);
}

.js-hero-teaser-slider .slick-dots li button:hover {
    background-color: var(--color-primary);
}

.js-hero-teaser-slider .slider__arrow-styled {
    margin-right: calc(8% + 2.25rem);
    bottom: -4rem;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .js-hero-teaser-slider .slider__arrow-styled {
        margin-right: calc(5% + 1rem);
    }
}

.js-hero-teaser-slider.hero-teaser--big-slides .slider__arrow-styled {
    bottom: -4rem;
    margin-right: 2.25rem;
}

@media screen and (max-width: calc(1500px + 2rem)) {
    .js-hero-teaser-slider.hero-teaser--big-slides .slider__arrow-styled {
        margin-right: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .js-hero-teaser-slider {
        padding: 0px !important;
    }
    .js-hero-teaser-slider .slick-slide {
        margin: 0 0.25rem;
    }
    .js-hero-teaser-slider .slider__arrow-styled {
        display: none !important;
    }
    .js-hero-teaser-slider .slick-dots {
        bottom: 0;
        padding-inline-start: 1rem !important;
    }
    .js-hero-teaser-slider .slick-dots li button {
        width: 8px !important;
        height: 8px !important;
    }
    .js-hero-teaser-slider .slick-dots li button::before {
        width: 8px !important;
        height: 8px !important;
    }
    .js-hero-teaser-slider .slick-dots {
        bottom: calc(4%) !important;
    }
    .js-hero-teaser-slider .hero-teaser-slider__content {
        bottom: calc(40rem / 16 + 1vw) !important;
    }
}
