.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
    margin-bottom: calc(10rem/16);
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    border: 2px solid var(--color-default);
    background: transparent;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
    z-index: 2;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: var(--color-default);
    visibility: hidden;
}

.custom-checkbox__active-box {
    position: absolute;
    left: calc(6rem/16);
    top: calc(6rem/16);
    overflow: hidden;
    width: calc(19rem/16);
    height: calc(19rem/16);
    background-color: var(--color-primary);
    visibility: hidden;
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box ~ .custom-checkbox__active-box {
    visibility: visible;
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-default);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    min-height: calc(22rem / 16);
}

.custom-checkbox--box-only {
    height: 1.25em;
}

.custom-checkbox a {
    text-decoration: underline;
}