
.tooltip{
    text-align: left;
    font-size: calc(12rem/16);
}

.copyright{
    font-size: calc(14rem/16);
    line-height: 1;
    padding: calc(3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.copyright--img{
    position: absolute;
    left: calc(5rem/16);
    top: calc(5rem/16);
    z-index: 5;
    color: #fff;
    
    @media screen and (min-width: 768px){
        left: calc(12rem/16);
        top: calc(12rem/16);
    }
}

.asset-tag {
    font-size: calc(14rem/16);
    line-height: 1;
    padding: calc(3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.asset-tag--img{
    position: absolute;
    right: calc(12rem/16);
    top: calc(12rem/16);
    z-index: 5;
    color: #fff;
}