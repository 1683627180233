.gallery__wrapper {
    position: relative;
    
    @media screen and (max-width: 767px){
       padding-bottom: calc(40rem/16);
        padding-top: calc(55rem/16);
    }
}
.gallery__wrapper:before {
    content: '';
    position: absolute;
    background-color: var(--color-light-grey);
    z-index: -1;

    @media screen and (max-width: 767px){
        top: 0;
        left: calc(-60rem/16);
        height: 71%;
        width: 62%;
    }

    @media screen and (min-width: 768px){
        left: calc(-60rem / 16);
        top: 30%;
        width: 55%;
        height: 45%;
    }
}

.gallery__item:not(:first-child) {
    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
    }
    
    margin-top: calc(8rem/16);
}


/*hover states*/
@media screen and (min-width: 1200px) {
    .gallery__item {
        position: relative;
    }
    .gallery__item .gallery-item__image-overlay {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        color: var(--color-white);
        font-size: calc(40rem/16);
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s ease;
    }
    .gallery__item:hover .gallery-item__image-overlay {
        opacity: 1;
    }
    .gallery__item-img {
        transform: scale(1);
        transition: transform 0.3s ease;
    }
    .gallery__item:hover .gallery__item-img {
        transform: scale(1.1);
        transition: transform 0.4s ease;
    }
}

.gallery__wrapper .slick-slide{
    @media screen and (max-width: 767px){
       margin: 0 calc(8rem/16);
    }
}

.gallery--offset .gallery__wrapper .gallery__wrapper-col:nth-child(2) {
    margin-top: calc(20rem / 16);
}

.gallery__item-video {
    clip-path: inset(1px 1px);
    background: none;
    object-fit: cover;
}