
.pagination__arrow .icon{
    vertical-align: calc(-3rem/16);
}

/*per Page Buttons*/
.pagination__perpage{
    display: flex;
    justify-content: center;
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        position: absolute;
        right: 0;
        top: 50%;
        width: calc(50rem/16);
        height: calc(22rem/16);
        transform: translateY(-50%);
        margin-top: 0;
    }
}
.pagination__perpage-item + .pagination__perpage-item{
    margin-left: calc(10rem/16);
    padding-left: calc(10rem/16);
    border-left: solid calc(1rem/16) var(--color-default);
}
.pagination__perpage-item.is-active{
    font-family: var(--font-default-bold);
}