.hero-teaser{
    position: relative;
    padding-bottom: 4%;
}

.hero-teaser:not(.hero-teaser--overview):after{
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        height: 45%;
        width: 52%;
        background-color: var(--color-light-grey);
        bottom: 0;
        left: -5%;
        z-index: -1;
    }

    @media screen and (min-width: 1200px){
        height: 33.333%;
    }
}

.hero-teaser.hero-teaser--big:not(.hero-teaser--overview):after{
    height: 93%;
    width: 60%;
    bottom: 0;
    left: auto;
    right: -5.5%;
}

.hero-teaser__content{
    position: absolute;
    left: 0;
    bottom: calc(40rem/16);
    z-index: 2;
    max-width: 67%;

    @media screen and (min-width: 768px){
        max-width: 52%;
        bottom: 8.7%;
    }

    @media screen and (min-width: 1200px){
        bottom: 7.9%;
    }
}

.hero-teaser--overview .hero-teaser__content{
    @media screen and (min-width: 768px){
        bottom: 22%;
    }
    
    @media screen and (max-width: 767px){
       position: relative;
        max-width: none;
        bottom: auto;
        margin-bottom: calc(20rem/16);
    }
}

.hero-teaser--big .hero-teaser__content{
    @media screen and (min-width: 768px){
        max-width: 36%;
    }
}

.hero-teaser__text{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    display: inline-block;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.hero-teaser__title{
    font-family: var(--font-default);

    /*calc(kleinste_fontsize + ((1vw - 992px/100) * 100 * (größte_fontsize - kleinste_fontsize) / (1920px - 992px))*/
    /*  font-size: calc(5rem + ((1vw - 0.62rem) * 5.3879));*/
    font-size: responsive calc(64rem/16) calc(86rem/16); /* min-size, max-size */
    font-range: calc(992rem/16) calc(1920rem/16); /* viewport widths between which font-size is fluid */
    display: block;
    line-height: 0.95;
    @media screen and (max-width: 767px){
       font-size: calc(34rem/16);
    }
}

.hero-teaser__toptitle{
    font-size: responsive calc(25rem/16) calc(28rem/16); /* min-size, max-size */
    font-range: calc(992rem/16) calc(1920rem/16); /* viewport widths between which font-size is fluid */
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(15rem/16);
}

.hero-teaser__img{
    position: relative;
    
    @media screen and (max-width: 767px){
       margin-right: calc(-20rem/16);
    }
}

.hero-teaser--overview .hero-teaser__img{
    @media screen and (max-width: 767px){
       margin-right: calc(-20rem/16);
    }
}

.hero-teaser--shadow .hero-teaser__img:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30%;
    z-index: 1;
    background: linear-gradient(90deg,hsla(0,0%,100%,.5) 0,hsla(0,0%,100%,.42) 45%,hsla(0,0%,100%,0));
}

.hero-teaser__content .btn{
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) calc(15rem/16);
        font-size: calc(11rem/16);
    }
}

@media screen and (min-width: 768px){
    .btn-md-lg {
        padding: 1.4375rem 1.875rem;
    }
}

.hero-teaser__overview{
    float: right;
    margin-top: calc(15rem/16);
    position: relative;
}

.hero-teaser__overview:before{
    content: '';
    position: absolute;
    background-color: var(--color-light-grey);
    left: calc(-50rem/16);
    right: calc(-50rem/16);
    bottom: 0;
    top: calc(-280rem/16);
    z-index: -1;
}

.hero-teaser__overview__label{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(13rem/16);

    @media screen and (min-width: 1200px){
        font-size: calc(16rem/16);
    }
}

.hero-teaser__overview ul>.list-inline-item{
    margin-right: calc(16rem/16);
    margin-bottom: calc(18rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(20rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-right: calc(40rem/16);
    }
}

.hero-teaser__back-link{
    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        left: 0;
       width: 25%;
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(15rem/16);
        display: inline-block;
    }
}