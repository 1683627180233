.rounded-icon {
    display: inline-block;
    border: 2px solid var(--color-text-default);
    border-radius: 50%;
    line-height: 1;
}

.rounded-icon .icon {
    position: relative;
    top: 1px;
    font-size: 3rem;
}

.rounded-icon.text-danger {
    border-color: var(--color-danger);
    color: var(--color-danger);
}

.large-icon {
    font-size: calc(25rem/16);
}

.responsive-large-icon {
    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16) !important;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(25rem/10) !important;
    }
}

.icon-multiple-img {
    background: url(../../../../web/static/icons/svg/multiple-img.svg) no-repeat;
    color: var(--color-primary);
    /* font-size: 1rem; */
}