.accordion .card{
    border-left: 0;
    border-right: 0;
}

.accordion .card-header{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    border-bottom: 0;
    padding: calc(16rem/16) calc(15rem/16) calc(16rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(23rem/16) calc(20rem/16) calc(23rem/16) 0;
    }
}


.accordion .card-header a {
    font-family: var(--font-default-bold);
    font-size: var(--font-size-default);
    margin-bottom: 0;
}
.accordion .card-header .h2-akkordion {
    margin-bottom: 0 !important;
}

.accordion .card-body {
    padding: 0 calc(60rem/16) calc(30rem/16) 0;
}

.accordion__toggle-icon{
    font-size: calc(20rem/16);
    transition: transform 0.25s ease;
}

.accordion-area h3 {
    font-family: var(--font-chronicle);
    font-weight: 600;
    font-size: 1.75rem;
}

.accordion__header-link {
    background: none;
    border: none;
    box-shadow: none;
    color: inherit;
}
.accordion__header-link:focus {
    outline: none;
}
.accordion__header-link .accordion__toggle-icon{
    transform: rotate(180deg);
}

.accordion__header-link.collapsed .accordion__toggle-icon{
    transform: rotate(0deg);
}

.card--light-grey {
    border: none;
}
.card--light-grey + .card--light-grey {
    margin-top: calc(1rem / 16);
}
.card--light-grey .card-header {
    background: var(--color-light-grey);
    color: var(--color-default);
    border: none;
}

.card--lg > .card-header  > .accordion__header-link {
    padding: calc(16rem / 16);
    font-size: calc(16rem / 16);
}
.card--lg .accordion__toggle-icon {
    font-size: calc(28rem / 16);
}
.card--lg .card-body {
    padding: 0 calc(16rem / 16);
}