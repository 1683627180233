.cart-detail__input{
    width: 60px;
    padding: 14px 5px;
    text-align: center;
}
.cart-detail__img {
    @media screen and (max-width:767px){
        margin: 0 auto;
        min-height: calc(150rem/16);
    }
}

.cart-detail__product-name {
    font-family: var(--font-default-bold);
    @media screen and (max-width:767px){
        font-size: calc(18rem/16);
    }
}

.cart-detail__item--bg-mobile {
    @media screen and (max-width:767px){
        background-color: var(--color-light-grey);
    }
}