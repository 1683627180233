.circle-img-teaser{
    transition: transform 250ms ease;
    padding-top: calc(5rem/16);
}

.circle-img-teaser:hover{
    transform: translateY(-5px);
}

.circle-img-teaser__img img.embed-responsive-item{
    border-radius: 50%;
    border: calc(6rem/16) solid #fff;
    transition: all 200ms ease;

    @media screen and (min-width: 768px){
        border: calc(10rem/16) solid #fff;
    }
}

.circle-img-teaser__img__inner{
    border-radius: 50%;
    border: 1px solid #f1f1f1;
}

.circle-img-teaser__title{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    line-height: calc(17/14);
    display: inline-block;
    margin-top: calc(10rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(9rem/16);
    }

    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
}

.circle-img-teaser-slider .slick-slide{
    margin: 0 calc(5rem/16);

    @media screen and (min-width: 1200px){
        margin: 0 calc(30rem/16);
    }

    @media screen and (min-width: 768px){
        margin: 0 calc(18rem/16);

    }
}

.slick-slide.active .circle-img-teaser .circle-img-teaser__img img.embed-responsive-item,
.circle-img-teaser.active .circle-img-teaser__img img.embed-responsive-item,
.circle-img-teaser:hover .circle-img-teaser__img img.embed-responsive-item {
    border-color: var(--color-primary);
}

.circle-img-teaser.active .circle-img-teaser__img__inner {
    border-radius: 50%;
    border: 1px solid transparent;
}