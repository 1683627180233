.filter-bar__main{
    @media screen and (min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.filter-bar__item:not(.filter-bar__item--country):not(.filter-bar__item--language){
    @media screen and (min-width: 768px){
        flex: auto;
        margin: calc(10rem/16) 0;
        margin-right: calc(10rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-right: calc(5rem/16);
        max-width: min(20rem, 100%);
        flex-basis: min-content;
    }
}

.filter-bar__dropdown-more{
    @media screen and (min-width: 768px){
        display: flex;
        padding: calc(15rem/16) 0;
    }

    @media screen and (min-width: 1200px){
        padding: calc(20rem/16) 0;
    }
}

.filter-bar__dropdown-more .filter-bar__item{
    @media screen and (min-width: 768px){
        /*flex: 0;*/
        padding-right: calc(35rem/16);
        flex:none;
        display:inline-flex;
        width:auto;
    }
    @media screen and (min-width: 1200px){
        padding-right: calc(60rem/16);
    }
}

.dropdown--filter-bar .dropdown-menu{
    padding: calc(25rem/16) calc(25rem/16)  calc(25rem/16);
    border-color: var(--color-default);
    min-width: calc(20rem);
    top: 100%;
    left: calc(-30rem/16);
    z-index: 7;
    background-color: #fff;
    
    @media screen and (min-width: 768px){
        top: calc(-20rem/16);
        padding: calc(65rem/16) calc(30rem/16)  calc(25rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1349px){
       font-size: calc(14rem/16);
    }
}

.dropdown--filter-bar .dropdown-menu.dropdown-menu--country, .dropdown--filter-bar .dropdown-menu.dropdown-menu--language{
    padding: calc(25rem/16) calc(25rem/16)  calc(25rem/16);
    border-color: var(--color-default);
    min-width: 27rem;
    top: 100%;
    z-index: 7;
    background-color: #fff;
    left: 0;
    @media screen and (min-width: 768px){
        left: calc(-30rem/16);
        top: calc(-20rem/16);
        padding: calc(65rem/16) calc(30rem/16)  calc(25rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1349px){
       font-size: calc(14rem/16);
    }
}

.dropdown--filter-bar .dropdown-menu.dropdown-menu--country .filter-bar__list, .dropdown--filter-bar .dropdown-menu.dropdown-menu--language .filter-bar__list{
        max-height: 15rem;
        overflow-y: auto;
}

.dropdown--filter-bar .dropdown-menu-big {
    min-width: 28rem;
}

.filter-bar__item:not(.filter-bar__item--country):not(.filter-bar__item--language):first-child .dropdown--filter-bar .dropdown-menu{
    @media screen and (min-width: 768px) and (max-width: 1345px){
        left: calc(-10rem/16);
    }
}

/*.filter-bar__item:last-child .dropdown--filter-bar .dropdown-menu{
    @media screen and (min-width: 768px) and (max-width: 1345px){
        left: auto;
        right: 0;
    }
}*/

.dropdown--filter-bar .dropdown-toggle{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    position: relative;
    z-index: 6;
    
    @media screen and (min-width: 768px) and (max-width: 1199px){
       font-size: calc(14rem/16);
    }
}

.dropdown--filter-bar.show .dropdown-toggle{
    z-index: 9;
}

.dropdown--filter-bar .dropdown-toggle:focus{
    outline: none;
}

.dropdown--filter-bar .dropdown-toggle:after{
    display: none;
}

.dropdown--filter-bar .dropdown-toggle__toggle{
    margin-left: calc(30rem/16);
}

.dropdown--filter-bar.show .dropdown-toggle__toggle{
    transform: rotate(180deg);
}

.dropdown--filter-bar.show:after{
    @media screen and (max-width: 1349px){
        content: '';
        position: absolute;
        bottom: -5px;
        height: 5px;
       /* background: #ffffff;*/
        z-index: 10;
        left: -15px;
        right: 0;
    }
}

.filter-bar__list{
    @media screen and (min-width: 768px){
        max-height: 15rem;
        overflow-y: auto;
    }
}

@media screen and (min-width: 768px){
    /* width */
    .filter-bar__list::-webkit-scrollbar {
        width: calc(21rem/16)
    }

    /* Track */
    .filter-bar__list::-webkit-scrollbar-track {
        background: #979797;
        border-left: calc(10rem/16) solid white;
        border-right: calc(10rem/16) solid white;
    }

    /* Handle */
    .filter-bar__list::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-left: calc(7rem/16) solid white;
        border-right: calc(7rem/16) solid white;
    }

    /* Handle on hover */
    .filter-bar__list::-webkit-scrollbar-thumb:hover {
        background: #D8D8D8;
    }
}

.product-grid__filter{
    @media screen and (max-width: 767px){
        position: fixed;
        overflow-y: auto;
        background: #fff;
        z-index: 200;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: calc(70rem/16) calc(50rem/16) calc(40rem/16);

        transform: translateX(100%);
        transition: transform 350ms ease;
    }
}

.product-grid__filter.is-open{
    @media screen and (max-width: 767px){
        transform: translateX(0);
    }
}

.product-grid__filter__close{
    @media screen and (max-width: 767px){
        position: absolute;
        right: 0;
        top: 0;
        font-size: calc(30rem/16);
        line-height: 1;
    }
}

.filter-bar__more-btn{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(12rem/16);
        padding: calc(10rem/16);
    }
}