.scrollbar::-webkit-scrollbar {
    width: calc(21rem/16)
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: #979797;
    border-left: calc(10rem/16) solid white;
    border-right: calc(10rem/16) solid white;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-left: calc(7rem/16) solid white;
    border-right: calc(7rem/16) solid white;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #D8D8D8;
}


/* Track */
.scrollbar.scrollbar--grey::-webkit-scrollbar-track {
    background: #979797;
    border-left: calc(10rem/16) solid var(--color-light-grey);
    border-right: calc(10rem/16) solid var(--color-light-grey);
}

/* Handle */
.scrollbar.scrollbar--grey::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-left: calc(7rem/16) solid var(--color-light-grey);
    border-right: calc(7rem/16) solid var(--color-light-grey);
}