.content-block,
.pimcore_area_content > .content-block {
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
        margin-bottom: calc(80rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-top: calc(110rem/16);
        margin-bottom: calc(110rem/16);
    }
}