.console {
    --console-main: var(--color-primary);
    --console-second: var(--color-secondary);
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
}
.console__list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: calc(10rem / 16);
}
.console__list-item {
    background: var(--console-main);
    color: var(--console-second);
    border-top-left-radius: calc(9rem / 16);
    border-bottom-left-radius: calc(9rem / 16);
    font-size: calc(14rem / 16);
    line-height: 1;
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    padding: calc(8rem / 16) calc(10rem / 16);
    transition: transform 200ms ease-in-out;
    transform: translateX(calc(100% - calc(50rem / 16)));
    height: calc(44rem / 16);
    box-shadow: 0 0 calc(2rem / 16) rgba(0, 0, 0, 0.2);

    /*    @media screen and (min-width: 768px) {
        font-size: calc(17rem / 16);
        height: calc(74rem / 16);
        transform: translateX(calc(100% - calc(80rem / 16)));
        padding: calc(10rem / 16) calc(24rem / 16);
    }*/
}
.console__list-item-icon {
    font-size: calc(24rem / 16);
    position: absolute;
    left: calc(12rem / 16);
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 200ms ease-in-out;

    /*    @media screen and (min-width: 768px) {
        font-size: calc(36rem / 16);
        left: calc(24rem / 16);
    }*/
}
.console__list-item-text {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.console__list-item-img {
    width: 24px;
}

/* open state */
@media screen and (hover: hover) {
    .console__list-item:hover {
        transform: translateX(0);
        background: var(--console-second);
        color: var(--console-main);
    }
    .console__list-item:hover .console__list-item-text {
        opacity: 1;
    }
    .console__list-item:hover .console__list-item-icon {
        opacity: 0;
    }
}
.console__list-item.is-open {
    transform: translateX(0);
    background: var(--console-main);
    color: var(--console-second);
}
.console__list-item.is-open .console__list-item-text {
    opacity: 1;
}
.console__list-item.is-open .console__list-item-icon {
    opacity: 0;
}
