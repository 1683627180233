
.custom-product-slider-section .product-teaser__counter {
    padding: 0.75rem;
}

.custom-product-slider-section .product-teaser__body {
    padding-bottom: 0.75rem;
}

@media screen and (max-width: 767px) {
    .custom-product-slider-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 3.5rem;
        padding-bottom: 0px !important;
    }
    .custom-product-slider-product-mobile {
        width: 50%;
    }
    .custom-product-slider-section {
        padding-bottom: calc(50rem/16);
    }
    .custom-product-slider-section .product-teaser__body {
        padding-bottom: 0rem;
    }
    .custom-product-slider-section .product-teaser__counter {
        padding: 0.25rem;
    }
}

@media screen and (max-width: 400px) {
    .custom-product-slider-mobile .product-teaser__body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.js-custom-img-slider {
    cursor: pointer;

    .slick-slide {
        overflow: hidden;
        padding-right: 1px;
        padding-left: 1px;
    }
}

.custom-img-slider-img-container {
    aspect-ratio: 1/1;
}
.custom-img-slider-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}